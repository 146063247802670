import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link} from "react-router-dom";

const HeaderAccountsPage = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const navigate = useNavigate();
  const location = useLocation();
  const redirectPage =projectInfo?.projectConfig?.config?.INITIAL_PAGE
  return (
    <header className="accountsPageHeader">
      <div className="left">
        <div className="logoContainer">
          <Link to={redirectPage==="LOGIN"?"/login":"/home"}>
            <h1>
              <img src={projectInfo?.projectConfig?.config?.LOGO} alt="Logo" />
            </h1>
          </Link>
        </div>
      </div>
      <div className="right">
        {location?.pathname === "/register" ? (
          <button onClick={() => navigate("/login")}>Login</button>
        ) : (
          <button onClick={() => navigate("/register",{state:{...location?.state}})}>Register</button>
        )}
      </div>
    </header>
  );
};

export default HeaderAccountsPage;
