import React from 'react'

import { imageUrlCheck } from '../../../utils/utils'
import {ReactComponent as Openurl} from "../../../assets/Icons/openurl.svg";
import { useSelector } from 'react-redux';

const AboutSection = ({showDetails,type}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  return (
    <div className="aboutSection"  style={{
      backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "var(--secondary-bg-color)" 
    }}>
        <h1 className="heading">ABOUT</h1>
        <div className="aboutLists">
          <ul>
            <div className="aboutContainer">
            {showDetails?.key_art_work?.map((item, index) => (
              <li className="aboutlist" key={index} >
                  {
                    item?.image &&
                    <div className="imageContainer">
                      
                      {
                        imageUrlCheck(item?.image) === true?(
                          <img src={item?.image} alt="Image" />

                        ):(
                          <img src={`${process.env.REACT_APP_IMAGE_URL + item?.image}`} alt="Image" />

                        )
                      }
                      <div className="openurl" onClick={()=>window.open(item?.imdb_link)}>
                        <Openurl/>
                      </div>
                    </div>
                  }
                <div className="info">
                    <h1 className="title">{item?.title}</h1>
                    <p className="description">{item?.description}</p>
                </div>
              </li>
            ))}
            </div>
          </ul>
        </div>
      </div>
  )
}

export default AboutSection
