import React, { useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loading from '../../Components/Loading/Loading';
import { appInfo } from '../../network/service';
import { getNewsDetails } from './service';
const NewsDetails = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [newsData, setNewsData] = useState([]);
  const [newDate,setNewDate] = useState("")
  const [loading, setLoading] = useState(true);
  const location =useLocation()
  const newsId = location?.state?.newsId
  const appInfo = {
    projectDetails: projectInfo,
    accessToken,
  };

  useEffect(()=>{
    if(newsData){
        let dates=new Date(newsData?.schedule_date)
        let month=dates.toLocaleString('en-us', { month: 'short' })
        let year = dates.getFullYear()
        let day = dates.getDate().toString().length == 1 ? '0' + dates.getDate() : dates.getDate()
        let isoFormat = day + " " + month+ " " + year
        setNewDate(isoFormat)
    }
  },[newsData])

  useEffect(()=>{
    fetchNewsDetails()
  },[])
    const fetchNewsDetails = async () => {
        setLoading(true)
        try{    
        const newsDetailsResponse = await getNewsDetails(appInfo,newsId)
            if(newsDetailsResponse?.status===200){
                setNewsData(newsDetailsResponse?.data?.data[0])
                setLoading(false)
            }
        }catch(err){    

        }
    }

    if(loading){
        return(
            <Loading/>
        )
    }
  return (
    <div className='articleInfo'>
        <div className="wrapper">
            <h1 className="heading">
                {newsData?.title}
            </h1>
            <div className="dateSection">
                {/* <span className="icon">
                </span> */}
                <span className="date">{newDate}</span>
            </div>
            <div className="articleDetails">
                <div className="left">
                    <div className="imageContainer">
                        <img src={newsData?.logo_landscape} alt="Episodes" />
                    </div>
                </div>
                <div className="right">
                    <p>{newsData?.description}</p>
                </div>
            </div>
            {/* <div className="videoContainer">
                <video controls src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"></video>
            </div> */}
        </div>
    </div>
  )
}

export default NewsDetails
