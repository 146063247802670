import { fetchApiData, fetchApiDataV2 } from "../../utils/utils";

export const getPlayerToken = async (appInfo,id) => {
  const api = "playlistV2/generateToken";
  const params = {
    id : id
  }
  return await fetchApiDataV2(appInfo,api,params)
  };
  
  export const getVideoDetails = async (id,appInfo) =>{
    const api =`video/${id}`
    return await fetchApiDataV2(appInfo,api)
}
