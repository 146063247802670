import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "./UserSlice/UserSlice";
import AppInfoReducer from "./AppInfo/AppInfoSlice";
import DeviceIdReducer from "./DeviceId/DeviceIdSlice";
import ATokenReducer from "./AToken/ATokenSlice";
import projectInfoReducer from "./ProjectInfo/ProjectInfoSlice";
import myListReducer from "./MyList/MyListSlice";
import userSubscriptionReducer from "./UserSubscriptionDetails/userSubscriptionSlice";
import deviceSubscriptionReducer from "./DeviceSubscriptionData/deviceSubscriptionSlice";
import UserDetailsReducer from "./UserDetailsSlice/UserDetailsSlice";
import sessionIdReducer from "./SessionId/sessionIdSlice";
import podcastReducer from "./PodcastModal/podcastModalSlice";
import howItWorksModalReducer from "./HowItWorksModal/howItWorksSlice";
import scheduleModalReducer from "./ScheduleModal/scheduleModalSlice";
import liveScheduleReducer from "./LiveScheduleData/liveScheduleDataSlice";
import selectedScheduleReducer from "./SelectedScheduleChannel/selectedScheduleChannelSlice";
import languageModalReducer from "./LanguageModal/languageModalSlice";
export default configureStore({
    reducer:{
        user:UserReducer,
        userDetails:UserDetailsReducer,
        appInfo:AppInfoReducer,
        deviceId:DeviceIdReducer,
        accessToken:ATokenReducer,
        projectInfo:projectInfoReducer,
        myList:myListReducer,
        userSubscription:userSubscriptionReducer,
        deviceSubscription:deviceSubscriptionReducer,
        sessionId:sessionIdReducer,
        podcastModal:podcastReducer,
        howItWorksModal:howItWorksModalReducer,
        scheduleModal:scheduleModalReducer,
        liveSchedule:liveScheduleReducer,
        selectedSchedule:selectedScheduleReducer,
        languageModal:languageModalReducer

    }
})