import React from "react";
import { ReactComponent as LoadingRing } from "../../assets/Icons/loadingRing.svg";

const Loading = () => {
  return (
    <div className="loadingContainer">
      <div className="loadingContent">
        <div className="loadingOverlay"></div>
        <div className="loading-container">
          <div className="loading">
            <div className="l1">
              <div></div>
            </div>
            <div className="l2">
              <div></div>
            </div>
            <div className="l3">
              <div></div>
            </div>
            <div className="l4">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
