import { fetchApiData, fetchApiDataV2 } from "../../utils/utils"

export const getShowDetails=async (appInfo,showId)=>{
    const api=`show/${showId}`
    return await fetchApiDataV2(appInfo,api)
}
export const getSimilarVideos=async (appInfo,showId)=>{
    const api=`show/similar/${showId}`
    return await fetchApiDataV2(appInfo,api)
}

export const updateLike = async (appInfo,showId,flag) => {
    const api = `like/show/${showId}/${flag}`
    return await fetchApiDataV2(appInfo,api)
}
