import React from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import { appInfo } from "../../network/service";
import { unsubscribe } from "../../Screens/Accounts/AccountSettings/service";

const CancelSubscriptionModal = ({setCancelSubModal,subId,fetchAccountDetails}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
    const cancelSubscriptionHandler =(status) => {
        if(status === "yes"){
            cancelSubscription()
        }else{
            setCancelSubModal(false)
        }
    }

    const cancelSubscription = async () => {
        try{

                const cancelSubscriptionResponse = await unsubscribe(appInfo,subId)
                if(cancelSubscriptionResponse?.status === 200) {
                    toast.success("Subscription cancelled", {
                        position: "bottom-center",
                    });
                    setCancelSubModal(false)
                    fetchAccountDetails()
                }
        }catch(err){
            toast.error(err.message, {
                position: "bottom-center",
              });

        }
    }
  return (
    <div className="cancelSub">
      <ToastContainer />

      <div className="overlayModal"></div>
     
          <div
            className="closeIconContainer"
            onClick={() => setCancelSubModal(false)}
          >
            <CloseIcon />
          </div>
      
      <div className="contents">
        <h1>
        Are you sure you want to cancel subscription?
        </h1>
        <div className="buttons">
            <div className="buttonContainer" onClick={()=>cancelSubscriptionHandler("yes")}>
            <div className="background"></div>
            <button>Yes</button>
            </div>
            <div className="buttonContainer" onClick={()=>cancelSubscriptionHandler("no")}>
            <div className="background"></div>
            <button>No</button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscriptionModal;
