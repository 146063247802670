import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const CurrentPasswordModal = (props) => {
  const [input, setInput] = useState("");
 
  const location = useLocation();
  const navigate = useNavigate();
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  useEffect(() => {
    
    
  }, []);

  const validationPasword = () => {
    const {currentPassword} = props?.values
    let validationStatus =  true;
    let errors = {}
    if(!currentPassword){
        errors.currentPassword="Current password is required!"
        validationStatus = false
    }
    props?.setErrors(errors)
    return validationStatus
  }

const submitButton = (e) =>{
    e.preventDefault();
    if(validationPasword()===true){
        props?.updateAccountDetails()
    }
    
}
  
  return (
    <div className="currentPasswordModal">
      <div className="overlayModal" onClick={()=>props.setPasswordModal(false)}></div>
      <div className="currentPasswordContainer">
        <label htmlFor="currentPassword">Enter your current password</label>
        <input
          type="password"
          name="currentPassword"
          id="currentPassword"
          value={props?.values?.currentPassword || ""}
          onChange={(item)=>props?.handleUpdate(item)}
          style={{
            borderColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? "var(--grey-color)" :"var(--text-color)"
          }}
        />
        {props?.errors?.currentPassword && <span className="error">{props?.errors?.currentPassword}</span>}
        <div className="buttonContainer" >
          <button onClick={(e)=>submitButton(e)}>Update</button>
        </div>
      </div>
    </div>
  );
};

export default CurrentPasswordModal;
