import { fetchApiData, updateApiData } from "../../../utils/utils";

export const registerUser = async (appInfo, values) => {
  const api = "account/register";
  const data = {
          first_name: values?.firstName,
          last_name: values?.lastName,
          dob :  values?.dob,
          user_email: values?.email,
          password: values?.password,
          gender : values?.gender
        };
        const location = {
          path:"common",
          values:data
      }
  return await updateApiData(appInfo, api, location);
};
export const verifyEmail = async (appInfo, value, userRegisterId) => {
  const api = "account/otp/verify";
  const params = {
    otp:value
  }
  return await fetchApiData(appInfo, api, params, userRegisterId);
};