import React, { useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchRecentStreamingDevices } from './service';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const StreamingActivity = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [RecenDevicesData,setRecentDevicesData] = useState()
  const navigate = useNavigate()
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(()=>{
    window.scroll(0,0)

    getRecentStreamingDevices()
  },[])

    const getRecentStreamingDevices = async () => {
        try{
            
            const response = await fetchRecentStreamingDevices(appInfo)
            if(response?.status === 200){
                setRecentDevicesData(response?.data?.data)
            }
        }catch(err){
            toast.error(err?.message, {
                position: "bottom-center",
              });
        }
    }
  return (
    <div className='streamingActivity'  style={{
      backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? "var(--grey-color)" :"var(--white-color)" 
    }}>

            <p>The most recently used devices and locations on your account.</p>
            <ul className="recentDeviceContainer">
                {
                    RecenDevicesData?.map((item,index)=>(
                        <li key={index}>
                            <span>{item?.location}</span>
                                <span>{item?.last_used}</span>
                        </li>

                    ))
                }
            </ul>
            
    </div>
  )
}

export default StreamingActivity