import React from 'react'
import { useSelector } from 'react-redux';

const OpenApp = ({setIsOpenAppFeature}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const notNowHandler = () => {
    setIsOpenAppFeature(false)
  }
  // window.addEventListener('visibilitychange', function() {
  //   clearTimeout(openAppTimeout);
  // });

  const openAppHandler = () => {
    window.location.href = projectInfo?.projectConfig?.config?.APPLINK_URL
  }
  return (
    <div className='openAppContainer'>
        <h1>{projectInfo?.projectConfig?.config?.TAB_TITLE} is better on the app</h1>
        <p>Open in the {projectInfo?.projectConfig?.config?.TAB_TITLE} app to get the full experience.</p>
        <div className="buttons">
            <button onClick={notNowHandler} className="notNow">Not now</button>
            <button onClick={openAppHandler} className="openApp">Switch to the app</button>
        </div>
    </div>
  )
}

export default OpenApp