import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useSelector } from 'react-redux';


const AppLoginModal = ({app}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [open, setOpen] = useState(true);


  const handleClose = (event,reason) => {
    if (reason && reason == "backdropClick") 
    return;
    setOpen(false);
  };
  const openAppHandler = () => {
    handleClose()
    window.location.href = projectInfo?.projectConfig?.config?.APPLINK_URL
  }
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        transitionDuration={500}
        maxWidth="xl"
        className='appLoginModal'
      >
        <DialogContent>
        <p>You are now signed in. If you are not automatically redirected in a few seconds, click the button below to continue.</p>
        <div className="buttonContainer" onClick={openAppHandler}>
            <div className="background"></div>
            <button>Open App</button>
        </div>
        <span>Your {app} app will now automatically update.</span>
        </DialogContent>
      </Dialog>
  )
}

export default AppLoginModal
