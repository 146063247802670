import React from 'react'
import { useNavigate } from 'react-router-dom'
import { imageUrlCheck } from '../../../utils/utils'
import { useSelector } from 'react-redux'

const CategoriesSection = ({showDetails,scrollDownRef}) => {
  const navigate = useNavigate()
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  return (
    <div className="categories" ref={scrollDownRef}  style={{
      backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "var(--secondary-bg-color)" 
    }}>
        <h1 className="heading">SHOWING AS PART OF</h1>
        <div className="categoryLists">
          <ul>
            {showDetails?.categories?.map((item, index) => (
              <li className="list" onClick={() =>
                navigate(`/category/${item?.key}`, {
                  state: {careers:item?.key,type:item?.type},
                })
              }>
                <div className="left">
                  {
                    item?.image &&
                  <div className="imageContainer">
                    {
                      imageUrlCheck(item?.image) === true?(
                        <img src={item?.image} alt="Image" />

                      ):(
                        <img src={`${process.env.REACT_APP_IMAGE_URL + item?.image}`} alt="Image" />

                      )
                    }
                  </div>
                  }
                </div>

                <div className="right">
                    <h1 className="title">{item?.category_name}</h1>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
  )
}

export default CategoriesSection