import React, { useMemo } from "react";
import { useState, Fragment } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "country-data-list";
import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CurrentPasswordModal from "../../../Components/Modals/CurrentPasswordModal";
import CancelSubscriptionModal from "../../../Components/Modals/CancelSubscriptionModal";
import LogoutAllModal from "../../../Components/Modals/LogoutAllModal";
import { getUserDetails } from "../../../Redux/UserDetailsSlice/UserDetailsSlice";
import Loading from "../../../Components/Loading/Loading";
import Accounts from "./Accounts";
import PlanDetails from "./PlanDetails";
import StreamingActivity from "./StreamingActivity";

const AccountSettings = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [accountDetails, setAccountDetails] = useState({});
  const [values, setValues] = useState({});
  const [PasswordModal, setPasswordModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [cancelSubModal, setCancelSubModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [profileWill, setProfileWill] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("account");
  const [activeTabName, setActiveTabName] = useState("ACCOUNT");

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    window.scroll(0, 0);
    fetchAccountDetails();
    // setCountryListOption(
    //   countries?.all?.map((item, index) => {
    //     const { alpha2, name } = item;
    //     return (
    //       <option key={index} value={alpha2}>
    //         {name}
    //       </option>
    //     );
    //   })
    // );
  }, []);
 

  const fetchAccountDetails = async (isUpdate) => {
    setLoading(true);
    try {
      const accountDetailsResponse = await service.getAccountDetails(appInfo);
      if (accountDetailsResponse?.status === 200) {
        setValues({
          ...values,
          email: accountDetailsResponse?.data?.data?.user_email,
          firstName: accountDetailsResponse?.data?.data?.first_name,
          lastName: accountDetailsResponse?.data?.data?.last_name,
          phone: accountDetailsResponse?.data?.data?.phone,
          country: accountDetailsResponse?.data?.data?.country_code,
        });
        setAccountDetails(accountDetailsResponse?.data?.data);
        if (isUpdate) {
          dispatch(
            getUserDetails({
              userDetails: accountDetailsResponse?.data?.data,
            })
          );
        }
        setLoading(false);
      }
    } catch (err) {}
  };
  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };


  const validation = () => {
    let validationStatus = true;
    let errors = {};
    let nameRegex = /^[a-zA-Z ]{3,30}$/;
    const { firstName, lastName, country } = values;

    if (!firstName) {
      errors.firstName = "First Name is required!";
      validationStatus = false;
    } else if (!firstName?.match(nameRegex)) {
      errors.firstName =
        "Name should be 3-30 characters and shouldn't include any special character";
      validationStatus = false;
    }

    if (!lastName) {
      errors.lastName = "Last Name is required!";
      validationStatus = false;
    } else if (!lastName?.match(nameRegex)) {
      errors.lastName =
        "Name should be 3-30 characters and shouldn't include any special character";
      validationStatus = false;
    }

    if (!country) {
      errors.country = "Country is required!";
      validationStatus = false;
    } else if (country === "Select Your Country") {
      errors.country = "Country is required!";
      validationStatus = false;
    }
    setErrors(errors);
    return validationStatus;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
   
    if (validation() === true) {
      setPasswordModal(true);
      
    } else {
      toast.error("Check the Fields!", {
        position: "bottom-center",
      });
    }
  };
  const updateAccountDetails = async () => {
    try {
     
      const response = await service.updateAccountdetails(appInfo, values);
      
      if (response?.data?.success === true) {
        toast.success("Account details updated", {
          position: "bottom-center",
        });
        setPasswordModal(false);
        fetchAccountDetails(true);
      }
    } catch (err) {
      console.log("erorr", err);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const handleFileChange = (e) => {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(e?.target?.files[0]?.name)) {
      alert("Invalid extension");
    } else {
      const name = e?.target?.files[0]?.name;
      const files = e?.target?.files;
      setValues({
        ...values,
        file: files[0],
      });
      setProfileWill(URL.createObjectURL(files[0]));
    }
  };

  const tabChange = (tabName) => {
    setActiveTab(tabName);
    switch (tabName) {
      case "account":
        setActiveTabName("ACCOUNT");
        break;
      case "plan":
        setActiveTabName("PLAN DETAILS");
        break;
      case "recentDevice":
        setActiveTabName("RECENT DEVICE STREAMING ACTIVITY");
        break;
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="accountSettings" style={{
      backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "var(--secondary-bg-color)" 
    }}>
      {PasswordModal && (
        <CurrentPasswordModal
          fetchAccountDetails={fetchAccountDetails}
          updateAccountDetails={updateAccountDetails}
          values={values}
          handleUpdate={handleUpdate}
          handleChange={handleChange}
          setValues={setValues}
          setErrors={setErrors}
          errors={errors}
          setPasswordModal={setPasswordModal}
        />
      )}
      {cancelSubModal && (
        <CancelSubscriptionModal
          fetchAccountDetails={fetchAccountDetails}
          setCancelSubModal={setCancelSubModal}
          subId={accountDetails?.sub_id}
        />
      )}
      {logoutModal && (
        <LogoutAllModal
          setLogoutModal={setLogoutModal}
          modalType={{
            type: "logoutAll",
            heading: "Do you want to logout from all devices?",
          }}
          appInfo={appInfo}
        />
      )}

      <div className="leftContainer">
        <ul className="tabs">
          <li
            className={activeTab === "account" ? "active" : "tab"}
            onClick={() => tabChange("account")}
          >
            ACCOUNT
          </li>
          {
            accountDetails?.sub_id &&
          <li
            className={activeTab === "plan" ? "active" : "tab"}
            onClick={() => tabChange("plan")}
          >
            PLAN DETAILS
          </li>
          }
          <li
            className={activeTab === "recentDevice" ? "active" : "tab"}
            onClick={() => tabChange("recentDevice")}
          >
            RECENT DEVICE STREAMING ACTIVITY
          </li>
          <li onClick={()=>setLogoutModal(true)}>SIGN OUT FROM ALL DEVICES</li>
        </ul>
      </div>

      <div className="rightContainer">
        <div className="top" style={{
          backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? "var(--grey-color)" :"var(--white-color)" 
        }}>
          <h1 className="heading">{activeTabName}</h1>
        </div>
          {
            activeTab=="account" &&
        <Accounts
          profileWill={profileWill}
          accountDetails={accountDetails}
          handleFileChange={handleFileChange}
          errors={errors}
          handleUpdate={handleUpdate}
          values={values}
          setErrors={setErrors}
          onSubmitHandler={onSubmitHandler}
        />
          }
        {
          activeTab ==='plan'&&
        <PlanDetails projectInfo={projectInfo} accountDetails={accountDetails} setCancelSubModal={setCancelSubModal} />
        }
        {
          activeTab === 'recentDevice' &&
          <StreamingActivity/>
        }
      </div>

      {/* <div className="wrapper">
        <div className="head">
          <h1 className="heading">Account Settings</h1>
        </div>

        <div className="contents">
          <div className="left">
            <div className="profileImageContainer">
            {
                profileWill?(
                  <img src={profileWill} alt="Profile" />

                  ):accountDetails?.user_image?(
                  <img src={accountDetails?.user_image} alt="Profile" />

                  ):(
                    <img src={require("../../../assets/Images/user_avatar.png")} alt="Avatar" />
                  )
              }
              <input type="file" id='file' accept="image/*" onChange={(e)=>handleFileChange(e)} />

              <label htmlFor="file">Change Picture</label>
            </div>
          </div>
          <div className="right">
            <div className="formSection">
              <form>
                <div className="half">
                  <div className="fname">
                    <label htmlFor="#fname">First Name</label>
                    <input
                      name="firstName"
                      value={values?.firstName || ""}
                      type="text"
                      id="fname"
                      onChange={(item) => handleUpdate(item)}
                    />
                    {errors?.firstName && (
                      <span className="error text-danger">
                        {errors?.firstName}
                      </span>
                    )}
                  </div>
                  <div className="lname">
                    <label htmlFor="#lname">Last Name</label>
                    <input
                      name="lastName"
                      type="text"
                      id="lname"
                      value={values?.lastName || ""}
                      onChange={(item) => handleUpdate(item)}
                    />
                    {errors?.lastName && (
                      <span className="error text-danger">
                        {errors?.lastName}
                      </span>
                    )}
                  </div>
                </div>
                <div className="half">
                  <div className="email">
                    <label htmlFor="email">Email</label>
                    <input
                      name="email"
                      value={values?.email || ""}
                      disabled
                      type="email"
                      id="email"
                      onChange={(item) => handleUpdate(item)}
                    />
                  </div>

                  <div className="country">
                    <label htmlFor="text">Country</label>
                    <select
                      name="country"
                      id="country"
                      onChange={(item) => handleUpdate(item)}
                    >
                      <option value={currentCountry?.value} >{currentCountry?.name}</option>
                      {countryListOption}
                    </select>
                    {errors?.country && (
                      <span className="error text-danger">
                        {errors?.country}
                      </span>
                    )}
                  </div>
                </div>
                <div className="full">
                  <div className="phone">
                    <label htmlFor="phone">Phone</label>
                    <input
                      name="phone"
                      type="tel"
                      id="phone"
                      value={values?.phone || ""}
                      onChange={(item) => handleUpdate(item)}
                    />
                  </div>
                  {accountDetails?.is_social_login === false && (
                    <Link to="/change-password">
                      <span>Change Password</span>
                    </Link>
                  )}
                </div>

                <div
                  className="buttonContainer"
                  onClick={(e) => onSubmitHandler(e)}
                >
                  <div className="background"></div>
                  <button type="submit" className="bg-button">
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {accountDetails?.sub_id && (
          <div className="planDetails">
            <div className="leftContainer">
              <h4 className="heading">PLAN DETAILS</h4>
            </div>
            <div className="rightContainer">
              <div className="left">
                {accountDetails?.cancel_status === false ? (
                  <>
                    <h2 className="planName">
                      {accountDetails?.subscription_name}
                    </h2>
                    <div
                      className="buttonContainer"
                      onClick={() => setCancelSubModal(true)}
                    >
                      <div className="background"></div>

                      <button>Cancel Membership</button>
                    </div>
                  </>
                ) : (
                  <p>Subscription cancelled</p>
                )}
              </div>

              <div className="right">
                <Link to="billing-activity">
                  <span>Billing details</span>
                </Link>
                {accountDetails?.cancel_status === true ? (
                  <Link to="/subscription">
                    <span>Subscribe</span>
                  </Link>
                ) : (
                  <Link
                    to="/subscription"
                    state={{ type: "upgrade", subId: accountDetails?.sub_id }}
                  >
                    <span>Change plan</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="settings">
          <div className="leftContainer">
            <h4 className="heading">SETTINGS</h4>
          </div>
          <div className="rightContainer">
            <ul>
              <Link to="streaming-activity">
                <li>
                  <span>Recent device streaming activity</span>
                </li>
              </Link>
              <li onClick={() => setLogoutModal(true)}>
                <span>Sign out of all devices</span>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AccountSettings;
