import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageModal } from '../../Redux/LanguageModal/languageModalSlice';
const LanguageModal = () => {
  const selectedLanguageData = useSelector((state) => state?.languageModal?.value);
    
    const [selectedValue,setSelectedValue] = useState()
    const [initialSelectedValue,setInitialSelectedValue] = useState()
    const dispatch = useDispatch();
    const defaultValue = {value:"",label:"Select a Language"}
    const [options,setOptions] = useState()
  

    useEffect(()=>{

        fetchOptions().then((data)=>{
            setOptions(data)
            if(selectedLanguageData && selectedLanguageData?.selectedLanguage){
                const initialValue = data?.filter((option)=>option?.label===selectedLanguageData?.selectedLanguage)
                setSelectedValue(initialValue[0])
            }else{
                setSelectedValue(defaultValue)
            }
        })
        
    },[selectedLanguageData])


    const closeHandler = () => {
        dispatch(
            getLanguageModal({
                languageModal:{
                    isOpen:false,
                    selectedLanguage:null
                }
            })
        )
    }

    const fetchOptions = () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve([
                { value: 'English', label: 'English' },
                { value: 'Malayalam', label: 'Malayalam' },
                { value: 'Arabic', label: 'Arabic' },
            ]);
          }, 1000);
        });
      };

    const handleChange = (selectedValue) => {
        setSelectedValue(selectedValue)
    }

    const submitButtonHandler = () => {
        if(selectedValue&&selectedValue?.value){
            dispatch(
                getLanguageModal({
                    languageModal:{
                        isOpen:false,
                        selectedLanguage:selectedValue?.label
                    }
                })
            )
        }
    }

   
  return (
    <div className='languageModal'>
      <div className="overlayModal"></div>
      <div className="closeIconContainer" onClick={closeHandler}>
        <CloseIcon />
      </div>

      <div className="contents">
        <h1>Choose Your Language</h1>
        <div className="inputContainer">
        <Select
        className="basic-single"
        classNamePrefix="select"
        value={selectedValue}
        isSearchable={true}
        name="color"
        options={options}
        onChange={handleChange}
      />
        </div>
        <div className="buttonContainer" onClick={submitButtonHandler}>
            <button>OK</button>
        </div>
      </div>
    </div>
  )
}

export default LanguageModal
