import axios from "axios";
import moment from "moment";
const qs = require('query-string');

export const getSessionId = (appInfo) => {
    let date = new Date();
    let millis = date.getTime();
    let deviceId = appInfo?.projectDetails?.device_id;
    let sessionId = millis + deviceId;
    return sessionId
  };

  export const convertAdUrl = (videoDetails, showDetails,appInfo) => {
    const adUrl = showDetails?.ad_link || videoDetails.ad_link;
    const currentLocation = appInfo?.projectDetails?.geoInfo;
   

    let uId = localStorage.getItem("gid");
    let user_id = localStorage.getItem("userId");
    if (user_id) {
      uId = user_id;
    }
    const width = window.innerWidth;
    const height = window.innerHeight;
    const dnt = 0;
    const ipAddress = currentLocation?.query;
    const latitude = currentLocation?.lat;
    const longitude = currentLocation?.lon;
    const userAgent = navigator.userAgent;
    const deviceIfa = "";
    const uuid = "";
    const country = currentLocation?.country;
    const deviceId = appInfo?.projectDetails?.device_id; 
    const keyword = showDetails?.categories[0]?.category_name;
    const deviceModel = navigator.userAgent;
    const deviceMake = navigator.userAgent;
    const channelId = appInfo?.projectDetails?.projectConfig?.channelid;
    const userId = uId;
    const videoId = videoDetails.video_id;
    const bundleId = "";
    const appName = "fishonnetworktv";
    const duration = showDetails?.videos[0]?.video_duration
      ? showDetails?.videos[0]?.video_duration * 60
      : "";
    const appstoreUrl = window.location.href;
    const city = currentLocation?.city;
    const region = currentLocation?.region;
    const showid = showDetails?.show_id;
    const categories = showDetails?.categories
      .map((item) => item.category_id)
      .join();
  
    const finalAdurl = adUrl
      .replace("[WIDTH]", width)
      .replace("[HEIGHT]", height)
      .replace("[DNT]", dnt)
      .replace("[IP_ADDRESS]", ipAddress)
      .replace("[LATITUDE]", latitude)
      .replace("[LONGITUDE]", longitude)
      .replace("[USER_AGENT]", userAgent)
      .replace("[DEVICE_IFA]", deviceIfa)
      .replace("[UUID]", uuid)
      .replace("[COUNTRY]", country)
      .replace("[DEVICE_ID]", deviceId)
      .replace("[KEYWORDS]", keyword)
      .replace("[DEVICE_MODEL]", deviceModel)
      .replace("[DEVICE_MAKE]", deviceMake)
      .replace("[CHANNEL_ID]", channelId)
      .replace("[USER_ID]", userId)
      .replace("[VIDEO_ID]", videoId)
      .replace("[BUNDLE]", bundleId)
      .replace("[APP_NAME]", appName)
      .replace("[DURATION]", duration)
      .replace("[APP_STORE_URL]", appstoreUrl)
      .replace("[CITY]", city)
      .replace("[REGION]", region)
      .replace("[SHOW_ID]", showid)
      .replace("[CATEGORIES]", categories);
  
    return finalAdurl;
  };

  export const imageUrlCheck=(url)=>{
    if (/^https/.test(url)) {
      return true
    } else {
      return false
    }
  }

  export const rowItemCount = (count) => {
    let rowCount = ""
    switch(count){
      case "3":
      rowCount = "rowCount3"
      break;
      case "4":
      rowCount = "rowCount4"
      break;
      case "5":
      rowCount = "rowCount5"
      break;
      case "6":
      rowCount = "rowCount6"
      break;
      default:
    }
    return rowCount
  }

  export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  export const checkOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    //Check mobile device is Android
    if (/android/i.test(userAgent)) {
      //Add your Code here
      return "android";
    }
  
    //Check mobile device is IOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      //Add your Code here
      return "iPhone";
    }
  
    //Check device os is Windows (For Laptop and PC)
    if (navigator.appVersion.indexOf("Win") != -1) {
      //Add your Code here
      return "window";
    }
  
    //Check device os is MAC (For Laptop and PC)
    if (navigator.appVersion.indexOf("Mac") != -1) {
      //Add your Code here
      return "mac";
    }
    return "none";
  };

  export const getBrowserType = () => {
    var isFirefox = typeof InstallTrigger !== "undefined";
    if (isFirefox === true) {
      return "Firefox";
    }
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof window["safari"] !== "undefined" &&
            window["safari"].pushNotification)
      );
    if (isSafari === true) {
      return "Safari";
    }
    var isIE = false || !!document.documentMode;
    if (isIE === true) {
      return "IE";
    }
    var isEdge = !isIE && !!window.StyleMedia;
    if (isEdge === true) {
      return "Edge";
    }
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome === true) {
      return "Chrome";
    }
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") !== -1;
    if (isEdgeChromium === true) {
      return "EdgeChromium";
    }
  };

  export const getFilteredProjectConfig=(key,data)=>{
   
    let filteredItem=data?.filter((item)=>{
      return item?.key===key
    })
    return filteredItem[0]?.value
  }

  export const convertTimeToLocal = (date) => {
   
    let tempDate = moment.utc(date);
    let localDate = moment(tempDate).local();
    let timeData = localDate.format("hh:mm A");
    return timeData;
  };

  // export const fetchApiData=async (appInfo,api,location,userRegisterId)=>{
  //   let uid=localStorage.getItem("gid"); 
  //   let user_id=localStorage.getItem("userId");
  //   let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  //   let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
  //   let deviceId = appInfo?.projectDetails?.device_id;
  //   let token = appInfo?.accessToken;
  //   let pubid=appInfo?.projectDetails?.projectConfig?.pubid;
  //   let channelid=appInfo?.projectDetails?.projectConfig?.channelid;
  //   if(user_id){
  //       uid=user_id
  //   }
   
  //   if(location?.path === 'videoSubscription'){
  //     const customConfig = {
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       "Access-Control-Allow-Origin": true,
  //       crossorigin: true,
  //       "access-token": token,
  //       uid:  userRegisterId? userRegisterId : uid,
  //       pubid: pubid,
  //       country_code: countryCode,
  //       channelid: channelid,
  //       dev_id: deviceId,
  //       ip: ipaddress,
  //       device_type: "web",
  //     },
  //     params: {
  //       video_id: location?.value,
  //     },
  //   };
  //    return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)
  //   }else if(location?.path === 'search'){
  //     const customConfig = {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         "Access-Control-Allow-Origin": true,
  //         crossorigin: true,
  //         "access-token": token,
  //         uid:  userRegisterId? userRegisterId : uid,
  //         pubid: pubid,
  //         country_code: countryCode,
  //         channelid: channelid,
  //         dev_id: deviceId,
  //         ip: ipaddress,
  //         device_type: "web",
  //       },
  //       params: {
  //         key: location?.value,
  //       },
  //     };
  //      return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)
  //   }else if(location?.path === 'subscriptionList') {
  //     const customConfig = {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         "Access-Control-Allow-Origin": true,
  //         crossorigin: true,
  //         "access-token": token,
  //         uid:  userRegisterId? userRegisterId : uid,
  //         pubid: pubid,
  //         country_code: countryCode,
  //         channelid: channelid,
  //         dev_id: deviceId,
  //         ip: ipaddress,
  //         device_type: "web",
  //       },
  //       params: {
  //         pubid: pubid,
  //         country_code:countryCode,
  //       },
  //     };
  //     return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)

  //   }else if(location?.path === 'stripeWithCoupon'){
  //     const customConfig = {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         "Access-Control-Allow-Origin": true,
  //         crossorigin: true,
  //         "access-token": token,
  //         uid:  userRegisterId? userRegisterId : uid,
  //         pubid: pubid,
  //         country_code: countryCode,
  //         channelid: channelid,
  //         dev_id: deviceId,
  //         ip: ipaddress,
  //         device_type: "web",
  //       },
  //       params: {
  //         pubid: pubid,
  //         sub_id:location?.values?.subId,
  //         country_code:countryCode,
  //         coupon_id:location?.values?.couponId
  //       },
  //     };
  //     return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)
  //   }else if(location?.path === 'stripe') {
  //     const customConfig = {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         "Access-Control-Allow-Origin": true,
  //         crossorigin: true,
  //         "access-token": token,
  //         uid:  userRegisterId? userRegisterId : uid,
  //         pubid: pubid,
  //         country_code: countryCode,
  //         channelid: channelid,
  //         dev_id: deviceId,
  //         ip: ipaddress,
  //         device_type: "web",
  //       },
  //       params: {
  //         pubid: pubid,
  //         sub_id:location?.values?.subId,
  //         country_code:countryCode,
  //       },
  //     };
  //     return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)

  //   }else if(location?.path === 'androidSubscription'){
  //     console.log(location,"from android");
  //     console.log(appInfo,"android appinfo");
  //     const customConfig = {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         "Access-Control-Allow-Origin": true,
  //         crossorigin: true,
  //         "access-token": token,
  //         uid:  userRegisterId? userRegisterId : uid,
  //         pubid: pubid,
  //         country_code: countryCode,
  //         channelid: channelid,
  //         dev_id: deviceId,
  //         ip: ipaddress,
  //         device_type: "android",
  //       },
  //       params: {
  //         pubid: pubid,
  //         key:location?.values?.androidtoken,
  //         country_code:countryCode,
  //       },
  //     };
  //     return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)
  //   }else  if(location?.path==="forgot-password"){
  //     const customConfig = {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         "Access-Control-Allow-Origin": true,
  //         crossorigin: true,
  //         "access-token": token,
  //         uid:  userRegisterId? userRegisterId : uid,
  //         pubid: pubid,
  //         country_code: countryCode,
  //         channelid: channelid,
  //         dev_id: deviceId,
  //         ip: ipaddress,
  //         device_type: "web",
  //       },
  //       params: {
  //         user_email: location?.value,
  //       },
  //     };
  //      return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)}
  //      else if(location?.path==="otp-verify"){
  //       const customConfig = {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           "Access-Control-Allow-Origin": true,
  //           crossorigin: true,
  //           "access-token": token,
  //           uid:  userRegisterId? userRegisterId : uid,
  //           pubid: pubid,
  //           country_code: countryCode,
  //           channelid: channelid,
  //           dev_id: deviceId,
  //           ip: ipaddress,
  //           device_type: "web",
  //         },
  //         params: {
  //           otp: location?.value,
  //         },
  //       };
  //        return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)
  //     }else if(location?.path==="paypal"){
  //       const customConfig = {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           "Access-Control-Allow-Origin": true,
  //           crossorigin: true,
  //           "access-token": token,
  //           uid:  userRegisterId? userRegisterId : uid,
  //           pubid: pubid,
  //           country_code: countryCode,
  //           channelid: channelid,
  //           dev_id: deviceId,
  //           ip: ipaddress,
  //           device_type: "web",
  //         },
  //         params:{
  //             sub_id: location?.values?.subId,

  //         }
  //       }
  
  //       return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)
  //     }else if(location?.path === "videoAnalytics"){

  //     }
    
  //   else{
  //     const customConfig = {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         "Access-Control-Allow-Origin": true,
  //         crossorigin: true,
  //         "access-token": token,
  //         uid:  userRegisterId? userRegisterId : uid,
  //         pubid: pubid,
  //         country_code: countryCode,
  //         channelid: channelid,
  //         dev_id: deviceId,
  //         ip: ipaddress,
  //         device_type: "web",
  //       },
  //     }

  //     return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)
  //   }
  
    

      
    
      


  // }
  
  export const fetchApiData = async (appInfo,api,params,userRegisterId) => {
    let uid=localStorage.getItem("gid"); 
    let user_id=localStorage.getItem("userId");
    let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
    let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
    let deviceId = appInfo?.projectDetails?.device_id;
    let token = appInfo?.accessToken;
    let pubid=appInfo?.projectDetails?.projectConfig?.pubid;
    let channelid=appInfo?.projectDetails?.projectConfig?.channelid;
    if(user_id){
        uid=user_id
    }
    const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        "access-token": token,
        uid:  userRegisterId? userRegisterId : uid,
        pubid: pubid,
        country_code: countryCode,
        channelid: channelid,
        dev_id: deviceId,
        ip: ipaddress,
        device_type: "web",
      },
      params: params&& params
    };
    return await axios.get(`${process.env.REACT_APP_API_URL}${api}`,customConfig)

  }
  export const fetchApiDataV2 = async (appInfo, api, params, userRegisterId) => {
    let uid = localStorage.getItem("gid");
    let user_id = localStorage.getItem("userId");
    let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
    let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
    let deviceId = appInfo?.projectDetails?.device_id;
    let token = appInfo?.accessToken;
    let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
    let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
    let deviceType = localStorage.getItem("deviceType")
    if (user_id) {
      uid = user_id;
    }
    const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        "access-token": token,
        uid: userRegisterId ? userRegisterId : uid,
        pubid: pubid,
        country_code: countryCode,
        channelid: channelid,
        dev_id: deviceId,
        ip: ipaddress,
        device_type: deviceType ? deviceType : "web",
      },
      params: params && params,
    };
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL_V2}${api}`,
        customConfig
      );
      return response
  
    } catch (err) {
      if (err?.response?.status === 404) {
        window.location.href = "/404?nf=true"
      }
  
    }
  
  };
  export const updateApiData=async (appInfo,api,location)=>{
   
    let uid=localStorage.getItem("gid"); 
    let user_id=localStorage.getItem("userId");
    let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
    let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
    let deviceId = appInfo?.projectDetails?.device_id;
    let token = appInfo?.accessToken;
    let pubid=appInfo?.projectDetails?.projectConfig?.pubid;
    let channelid=appInfo?.projectDetails?.projectConfig?.channelid;
    if(user_id){
        uid=user_id
    }
    const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        "access-token": token,
        uid: uid,
        pubid: pubid,
        country_code: countryCode,
        channelid: channelid,
        dev_id: deviceId,
        ip: ipaddress,
        device_type: "web",
      },
    };
    const data = location?.values
    if(location?.path === "accountUpdate"){
      const formData = new FormData();
      if (location?.values?.newPassword) {
        formData.append("password", location?.values?.cPassword);
        formData.append("new_password", location?.values?.newPassword);
      }else{
        formData.append("password", location?.values?.currentPassword);
        formData.append("file", location?.values?.file);
        formData.append("country_code", location?.values?.country);
        formData.append("first_name", location?.values?.firstName);
        formData.append("last_name", location?.values?.lastName);
        formData.append("phone", location?.values?.phone);
      }  return await axios.post(`${process.env.REACT_APP_API_URL}${api}`,formData,customConfig,)
    }else{
      return await axios
        .post(
          `${process.env.REACT_APP_API_URL}${api}`,
          qs.stringify(data),
          customConfig
        )
    }
    
    
  

  }

  export const updateApiDataV2 = async (appInfo, api, location) => {
    let uid = localStorage.getItem("gid");
    let user_id = localStorage.getItem("userId");
    let countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
    let ipaddress = appInfo?.projectDetails?.geoInfo?.query;
    let deviceId = appInfo?.projectDetails?.device_id;
    let token = appInfo?.accessToken;
    let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
    let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
    let deviceType = localStorage.getItem("deviceType")
    if (user_id) {
      uid = user_id;
    }
    const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        "access-token": token,
        uid: uid,
        pubid: pubid,
        country_code: countryCode,
        channelid: channelid,
        dev_id: deviceId,
        ip: ipaddress,
        device_type: deviceType ? deviceType : "web",
      },
    };
    const data = location?.values;
    if (location?.path === "accountUpdate") {
      const formData = new FormData();
      if (location?.values?.newPassword) {
        formData.append("password", location?.values?.currentPassword);
        formData.append("new_password", location?.values?.newPassword);
      } else {
        formData.append("password", location?.values?.currentPassword);
        formData.append("file", location?.values?.file);
        formData.append("country_code", location?.values?.country);
        formData.append("first_name", location?.values?.firstName);
        formData.append("last_name", location?.values?.lastName);
        formData.append("phone", location?.values?.phone);
      }
      return await axios.post(
        `${process.env.REACT_APP_API_URL}${api}`,
        formData,
        customConfig
      );
    } else {
      return await axios.post(
        `${process.env.REACT_APP_API_URL}${api}`,
        qs.stringify(data),
        customConfig
      );
    }
  };
  export const onVideoPlayFunction=async (appInfo,videoDetails, event,currentTime,isLive,categories) =>{
    let sessionId = appInfo?.sessionId
    let uId=localStorage.getItem("gid");
    let videoTime =
      currentTime == 0 || currentTime == undefined ? "" : currentTime.toString();
      let user_id=localStorage.getItem("userId");
    if (user_id) {
      uId = user_id;
    }
    const deviceId=appInfo?.projectDetails?.device_id;
    let ctimestamp = Date.now().toString();
    let ctime = ctimestamp.slice(0, 10);
    const appid = appInfo?.projectDetails?.projectConfig?.app_id; 
    let token = appInfo?.accessToken;
    const channelId = appInfo?.projectDetails?.projectConfig?.channelid;
    const pubId = appInfo?.projectDetails?.projectConfig?.pubid;
    const data = {
      session_id: sessionId,
      user_id: uId,
      device_id: deviceId,
      publisherid: pubId,
      app_id: appid,
      channel_id: isLive == 1 ? videoDetails?.channel_id : channelId,
      event_type: event,
      video_id: videoDetails?.video_id?videoDetails?.video_id:"0",
      show_id:videoDetails?.showId,
      is_live:isLive,
      video_title: videoDetails?.video_title || videoDetails?.event_name,
      timestamp: ctime,
      video_time: videoTime,
      category: categories,
      event_id:videoDetails?.event_id,
      schedule_id:videoDetails?.now_playing?.id || videoDetails?.id ,
    };
    const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        "access-token": token,
      },
    };
    
    return await axios
      .post(
        "https://analytics.poppo.tv/event",
        qs.stringify(data),
        customConfig
      )
  }

  export const affliateAnalytics = async (appInfo) => {
    let deviceType;
    if(checkOperatingSystem()=== "android"){
      deviceType = "Android"
    }else if (checkOperatingSystem() === "iPhone"){
      deviceType = "iPhone"
    }
    let pubid = appInfo?.projectDetails?.projectConfig?.pubid;
    let channelid = appInfo?.projectDetails?.projectConfig?.channelid;
    const url = localStorage.getItem("affliateUrl")
    const unique = deviceType&& appInfo?.projectDetails?.geoInfo?.query + deviceType;
    let params = {url:url, source_application: document.referrer,unique_device_id:unique}
    let uid = localStorage.getItem("gid");
    let user_id = localStorage.getItem("userId");
    if (user_id) {
      uid = user_id;
    }
    const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        pubid:pubid,
        channelid:channelid,
        uid:uid,
        device_type:"web"
      },
      params:params
    };
  
    return await axios.get(
      "https://api.gizmott.com/api/v1/affiliate/analytics",
      customConfig
    );
  };
  export const ideaBizSubscribe =  async (appInfo,values) => {
    const api = "subscription/ideabiz/subscribe"
    const location = {
      path:"common",
      values
    }
    return await updateApiData(appInfo,api,location)
  }
  export const ideaBizOTPVerify =  async (appInfo,values) => {
    const api = "subscription/ideabiz/submitPIN"
    const location = {
      path:"common",
      values
    }
    return await updateApiData(appInfo,api,location)
  }
  
  export const ideaBizPurchase =  async (appInfo,values) => {
    const api = "subscription/ideabiz/purchase"
    const location = {
      path:"common",
      values
    }
    return await updateApiData(appInfo,api,location)
  }
  export const timeComparisonIsAfter = (endTime) => {
    const formatEndTime = moment(endTime)
    const currentTime = moment();
  if (formatEndTime.isAfter(currentTime)) {
    return true
  }else{
    return false
  }
  
  }
  export const timeFormatter = (timestamp) => {
    const formattedTime = moment(timestamp).format('hh:mm A');
    return formattedTime
    }

  export const timeConversion = (minutes) => {
      var duration = moment.duration(minutes, 'minutes');
    var hours = Math.floor(duration.asHours());
    var remainingMinutes = duration.minutes();
    let format = hours + ' hours ' + remainingMinutes + ' minutes' 
    return format
    }
    export const getCurrentTimeAndDate = () => {
      let currentTime = moment().format('YYYY-MM-DDTHH:mm:ss');
      return currentTime
    }
    export const getCurrentTime = () => {
      const moment = require('moment');
    let now = moment();
    let currentTime = now.format('hh:mm A');
    return currentTime
    }
    export const  convertTo12HourFormat = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      
      // Convert hours to 12-hour format
      let formattedHours = hours % 12;
      if (formattedHours === 0) {
        formattedHours = 12;
      }
      // Determine AM or PM
      const period = hours>=60?'pm': hours>=48?'am': hours >= 36 ? 'pm' :hours>=12?'am' :'pm';
      
      // Construct the formatted time
      const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
      
      return formattedTime;
    }

    export const getCurrentUTCTimestamp = () => {
      const currentUtcTimestamp = moment().utc().unix();;
      return currentUtcTimestamp
    }
    
    export const timeDifferenceWithCurrenttime = (time) => {
      const currentTimeUnix = Math.floor(Date.now() / 1000);
      const timeDifference = currentTimeUnix - time;
      if (timeDifference <= 0) {
        return "Just now";
      } else if (timeDifference < 60) {
        return `${timeDifference} second${timeDifference === 1 ? '' : 's'} ago`;
      } else if (timeDifference < 3600) {
        const minutes = Math.floor(timeDifference / 60);
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
      } else if (timeDifference < 86400) {
        const hours = Math.floor(timeDifference / 3600);
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
      } else if (timeDifference < 604800) {
        const days = Math.floor(timeDifference / 86400);
        return `${days} day${days === 1 ? '' : 's'} ago`;
      } else if (timeDifference < 2419200) {
        const weeks = Math.floor(timeDifference / 604800);
        return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
      } else if (timeDifference < 29030400) {
        const months = Math.floor(timeDifference / 2419200);
        return `${months} month${months === 1 ? '' : 's'} ago`;
      } else {
        const years = Math.floor(timeDifference / 29030400);
        return `${years} year${years === 1 ? '' : 's'} ago`;
      }
    };
    export const getEventInfo = async (appInfo, eventId) => {
      const api = `event/${eventId}`
      return await fetchApiData(appInfo, api)
    }
    export const convertDateAndTimeToLocal = (date) => {
      const localDateTime = moment.utc(date).local();
      const formattedDateTime = localDateTime.format('DD MMM YYYY hh:mm A');
      return formattedDateTime
    }
    export const handleChangeColor = (color) => {
      
      if (color?.BACKGROUND_COLOR) {
        document.documentElement.style.setProperty(
          "--bg-color",
          color?.BACKGROUND_COLOR
        );
      }
      if (color?.TEXT_COLOR) {
        document.documentElement.style.setProperty(
          "--text-color",
          color?.TEXT_COLOR
        );
      }
    
      // if (color?.BUTTON_COLOR) {
      //   document.documentElement.style.setProperty(
      //     "--button-color",
      //     color?.BUTTON_COLOR
      //   );
      //   document.documentElement.style.setProperty(
      //     "--secondary-color",
      //     color?.BUTTON_COLOR
      //   );
      // }
    
      // if (color?.BUTTON_SELECTION_COLOR) {
      //   document.documentElement.style.setProperty(
      //     "--button-hover",
      //     color?.BUTTON_SELECTION_COLOR
      //   );
      //   document.documentElement.style.setProperty(
      //     "--text-hover",
      //     color?.BUTTON_SELECTION_COLOR
      //   );
      // }
    
    };
