import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as Closeicon } from "../../assets/Icons/cross.svg";
import { ReactComponent as ShareIcon } from "../../assets/Icons/share.svg";
import { ReactComponent as ClockIcon } from "../../assets/Icons/clock.svg";
import { ReactComponent as Speaker } from "../../assets/Icons/speaker.svg";
import { ReactComponent as WatchlistAdd } from "../../assets/Icons/addToWatchlist.svg";
import { ReactComponent as WatchlistRemove } from "../../assets/Icons/removeFromWatchlist.svg";
import { ReactComponent as StarIcon } from "../../assets/Icons/star.svg";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { ReactComponent as PageDownArrow } from "../../assets/Icons/pageDownArrow.svg";
import { ReactComponent as ClipBoard } from "../../assets/Icons/clipBoard.svg";
import { ReactComponent as AddRatings } from "../../assets/Icons/starRounded.svg";
import { ReactComponent as AddToFavourite } from "../../assets/Icons/addToFav.svg";
import { ReactComponent as Favourited } from "../../assets/Icons/favoriteActive.svg";
import { ReactComponent as InstagramIcon } from "../../assets/Icons/instagram.svg";
import { ReactComponent as ArrowIcon } from "../../assets/Icons/arrow.svg";
import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { stripeSession } from "../Subscription/service";

import "react-toastify/dist/ReactToastify.css";
import { appInfo, authenticate, getPlayerToken, getUserSubscription, updateWatchlistData } from "../../network/service";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import { ToastContainer, toast } from "react-toastify";
import { convertAdUrl, imageUrlCheck, onVideoPlayFunction, rowItemCount } from "../../utils/utils";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import Loading from "../../Components/Loading/Loading";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import CategoriesSection from "./Components/CategoriesSection";
import CastAndCrew from "./Components/CastAndCrew";
import ShowCard from "../../Components/ShowCard/ShowCard";
import ShowsRow from "../../Components/ShowsRow/ShowsRow";
import ContinueWatchingModel from "../../Components/Modals/ContinueWatchingModel";
import TrailerModal from "../../Components/Modals/TrailerModal";
import AwardsAndFestivals from "./Components/AwardsAndFestivals";
import WatchWithoutAdsModal from "../../Components/Modals/WatchWithoutAdsModal";
import LogoutAllModal from "../../Components/Modals/LogoutAllModal";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import { useWindowWidth } from "@react-hook/window-size";
import RatingsAndReviewModal from "../../Components/Modals/RatingsAndReviewModal";
import AboutSection from "./Components/AboutSection";
import { getVideoSubscription } from "../Subscription/service";
let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;
const DetailsScreen = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const [isShareActive, setIsShareActive] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [isContinueWatchingModal, setIsContinueWatchingModal] = useState(false);
  const [trailerModal, setTrailerModal] = useState(false);
  const [watchDuration, setWatchDuration] = useState("");
  const [pauseVideo, setPauseVideo] = useState(true);
  const [pageURL, setPageURL] = useState(0);
  const [showDetails, setShowDetails] = useState({});
  const [similarVideos, setSimilarVideos] = useState([]);
  const [playerToken, setPlayerToken] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPlayer, setVideoPlayer] = useState("");
  const [videoDetail, setVideoDetail] = useState();
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [favouriteStatus, setFavouriteStatus] = useState();
  const [watchWithoutAds, setWatchWithoutAds] = useState(false);
  const [videoSubscriptions, setVideoSubscriptions] = useState([]);
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [logoutModal, setLogoutModal] = useState(false);
  const [categories, setCategories] = useState("");
  const [trailerPlaying, setTrailerPlaying] = useState(true);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [adUrl, setAdUrl] = useState("");
  const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subscribedUser, setSubscribeduser] = useState(false);
  const [browserPipMode, setBrowserPipMode] = useState(false);
  const [pipMode, setPipMode] = useState(false);
  const [nowPlaying, setNowPlaying] = useState("");
  const [episodes, setEpisodes] = useState();
  const [nextEpisodeModal, setNextEpisodeModal] = useState(false);
  const [isFreeVideo, setIsFreeVideo] = useState(false); // for inital free video checking for showing button name "subscribe" ,"watchNow" or "watch for free"
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [rowItemsClass, setRowItemsClass] = useState("");
  const [ratingReviewModal, setRatingReviewModal] = useState(false);
  const [userRatedCount, setUserRatedValue] = useState();
  const position = useScrollPosition();
  const windowSize = useWindowWidth();

  const location = useLocation();
  const showId = location?.state?.showId;
  const type = location?.state?.type;
  const vanityUrl = location?.state?.vanityUrl;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let shareRef = useRef(null);
  let reportRef = useRef(null);
  let shareIconRef = useRef(null);
  let shareTwoRef = useRef(null);
  let reportTwoRef = useRef(null);
  let shareIconTwoRef = useRef(null);
  let scrollDownRef = useRef(null);
  let episodeCount = 0;
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  const playerRef = useRef(null);

  useEffect(() => {
    if (projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT") {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    if (windowSize > 980) {
      if (projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) {
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT));
      } else {
        setRowItemsClass((thumbnailOrientation == "PORTRAIT" ? "rowCount6" : "rowCount5"));
      }
    } else {
      setRowItemsClass("rowCount5");
    }
  }, [projectInfo, windowSize]);

  useEffect(() => {
    if (position === 0) {
      setPipMode(true);
    }
  }, [position]);

  useEffect(() => {
    window.scroll(0, 0);
    if (location?.pathname) {
      let vanityUrl = location?.pathname.split("/")[2];
      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        fetchShowDetailsByVanityUrl(vanityUrl);
      } else {
        fetchShowDetails();
      }

      if (showId) {
        fetchSimilarVideos();
      }
    }
  }, [accessToken, projectInfo, location?.pathname]);

  useEffect(() => {
    if (showDetails?.user_rated === true) {
      setUserRatedValue(showDetails?.user_rating);
    }
    if (showDetails?.liked_flag === 1) {
      setFavouriteStatus("liked");
    } else {
      setFavouriteStatus("disliked");
    }

    if (showDetails?.watchlist_flag === 1) {
      setWatchlistStatus("added");
    } else {
      setWatchlistStatus("removed");
    }

    if (showDetails?.single_video === 0 || showDetails?.single_video === 1) {
      if (showDetails?.single_video === 1) {
        setSelectedVideoDetails(showDetails?.videos[0] && showDetails?.videos[0]);
        setInitialVideoSubscriptions(showDetails?.videos[0]?.subscriptions);
        if (showDetails?.videos[0]?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {
          let isSubscribedUser;
          if (showDetails?.subscriptions?.length > 0) {
            isSubscribedUser =
              userSubscriptionData?.data?.length > 0
                ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                : false;
          } else {
            isSubscribedUser =
              userSubscriptionData?.data?.length > 0
                ? subscriptionCheck(userSubscriptionData?.data, showDetails?.videos[0]?.subscriptions)
                : false;
          }

          if (isSubscribedUser === true) {
            setSubscribeduser(true);
          } else {
            setSubscribeduser(false);
            if (showDetails?.videos[0]?.free_video === true) {
              // for inital free video checking for showing button name "subscribe" ,"watchNow" or "watch for free"
              setIsFreeVideo(true);
            } else {
              setIsFreeVideo(false);
            }
          }
        } else {
          setSubscribeduser(true);
        }
      } else {
        let video = [];

        if (showDetails?.videos?.length === 1 && showDetails?.videos[0]?.season) {
          setEpisodes(showDetails?.videos[0]?.videos);
        } else if (!showDetails?.videos[0]?.videos) {
          setEpisodes(showDetails?.videos);
        }

        if (showDetails?.videos[0]?.videos && showDetails?.videos[0]?.videos[0]) {
          video = showDetails?.videos[0]?.videos[0];
        } else {
          video = showDetails?.videos && showDetails?.videos[0];

        }

        setSelectedVideoDetails(video);

        setInitialVideoSubscriptions(video?.subscriptions);
        if (video?.subscriptions?.length > 0 || showDetails?.suscriptions?.length > 0) {
          let isSubscribedUser;
          if (showDetails?.suscriptions?.length > 0) {
            isSubscribedUser =
              userSubscriptionData?.data?.length > 0
                ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                : false;
          } else {
            isSubscribedUser =
              userSubscriptionData?.data?.length > 0
                ? subscriptionCheck(userSubscriptionData?.data, video?.subscriptions)
                : false;
          }

          if (isSubscribedUser === true) {
            setSubscribeduser(true);
          } else {
            if (video?.free_video === true) {
              // for inital free video checking for showing button name "subscribe" ,"watchNow" or "watch for free"
              setIsFreeVideo(true);
            } else {
              setIsFreeVideo(false);
            }
            setSubscribeduser(false);
          }
        } else {
          setSubscribeduser(true);
        }
      }
    }

    if (showDetails?.categories) {
      let categoryNames;
      showDetails?.categories?.map((item) => {
        categoryNames = categoryNames ? categoryNames + item?.category_name + "," : item?.category_name + ",";
      });
      setCategories(categoryNames);
    }
    if (showDetails) {
      if (showDetails?.videos) var updateDuration = showDetails?.videos[0]?.watched_duration;

      setWatchDuration(updateDuration);
    }
  }, [showDetails, location?.pathname]);

  useEffect(() => {
    setVideoPlayer(null);
    if (videoUrl) {
      const videoJsOptions = {
        autoplay: videoPlay,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],
        // poster:require("../../../images/thumb.png")
      };

      setVideoPlayer(
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
          pauseVideo={pauseVideo}
        />
      );
    }
  }, [videoUrl]);

  useEffect(() => {
    setPageURL(window.location.href);
    fetchUserSubscriptionDetails();
  }, []);


  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };

  const fetchShowDetails = async () => {
    try {
      setLoading(true);
      const showDetailsResponse = await service.getShowDetails(appInfo, showId);
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchShowDetailsByVanityUrl = async (vanityUrl) => {
    try {
      setLoading(true);
      const showDetailsResponse = await service.getShowDetails(appInfo, vanityUrl);
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);
        setLoading(false);
        if (!showId) {
          fetchSimilarVideos(showDetailsResponse?.data?.data?.show_id);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchSimilarVideos = async (sid) => {
    let id = showId ? showId : sid;
    const similarVideosResponse = await service.getSimilarVideos(appInfo, id);
    if (similarVideosResponse?.status === 200) {
      setSimilarVideos(similarVideosResponse?.data?.data);
    }
  };

  const fetchPlayerToken = async (isTrailer) => {
    const playerTokenResponse = await getPlayerToken(accessToken);
    if (playerTokenResponse?.status === 200) {
      setPlayerToken(playerTokenResponse?.data?.data);
      if (isTrailer) {
        let arr = showDetails?.teaser?.split("/").reverse();
        setVideoUrl(
          "https://poppo.tv/playlist/playlist.m3u8?id=" +
          arr[1] +
          "&token=" +
          playerToken +
          "&type=trailer&auth=<authtoken>"
        );
      }
      return playerTokenResponse?.data?.data;
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);


      if (response?.status === 200) {
        if (flag === 1) {
          toast.success("Added to mylist", {
            position: "bottom-center",
            toastId: "success1",
          });
          setWatchlistStatus("added");
        } else {
          toast.success("Removed from mylist", {
            position: "bottom-center",
          });
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const addToWatchlist = (show_id) => {
    if (user) {
      updateWatchlist(show_id, 1);
    } else {
      navigate("/login", { state: { path: location?.pathname } });
    }
  };
  const removeFromWatchlist = (show_id) => {
    updateWatchlist(show_id, 0);
  };

  const addToFavourites = () => {
    if (user) {
      updateFavourites(1);
    } else {
      navigate("/login", { state: { path: location?.pathname } });
    }
  };
  const removeFromFavourites = () => {
    updateFavourites(0);
  };

  const updateFavourites = async (flag) => {
    const response = await service.updateLike(appInfo, showDetails?.show_id, flag);
    if (response?.status === 200) {
      if (flag === 1) {
        setFavouriteStatus("liked");
      } else {
        setFavouriteStatus("disliked");
      }
    }
  };

  const subscriptionCheck = (userSub, videoSub) => {
    return userSub.every((userSubscription) =>
      videoSub.some((videosubscription) => userSubscription?.sub_id === videosubscription?.subscription_id)
    );
  };

  const playVideo = async (videoDetails, showDetails, ads) => {
    let adURL = ads ? ads : adUrl
    let videoType = showDetails?.type;
    navigate(`/videos/${videoDetails?.vanity_url}`, {
      state: { videoDetails, categories, type: videoType, showDetails },
    });

  };

  const redirectToLogin = () => {
    dispatch(
      getUser({
        user: null,
      })
    );
    localStorage.removeItem("userId");
    tokenAuthenticate();
    navigate("/login", {
      state: { path: location?.pathname, showId: showId },
    });
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
    }
  };
  const fetchVideoSubscription = async (videoDetails) => {
    try {
      const type = "video";
      const videoSubscriptionRespone = await getVideoSubscription(appInfo, videoDetails?.video_id, type);
      if (videoSubscriptionRespone?.status === 200) {
        return videoSubscriptionRespone?.data?.data;
      }
    } catch (err) { }
  };



  const handleSubscribe = (data) => {

    if (user) {

      let vanityUrl = location?.pathname.split("/")[2];

      if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
        localStorage.setItem("vanityUrl", vanityUrl);
      } else {
        localStorage.setItem("showId", showId);
      }

      localStorage.setItem("selectedAmount", data?.price);
      localStorage.setItem("selectedSubId", data?.subscription_id);
      localStorage.setItem("deviceType", "web");

      if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {

        proceedToPayment(data?.subscription_id);
      } else {
        navigate("/payment", {
          state: { subscription: { ...data, is_coupon: false } },
        });
      }
    } else {
      if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {
        navigate("/checkout", { state: { showId: showId, subscriptionId: data?.subscription_id, subscriptionPrice: data?.price } });
      } else {
        navigate("/login", {
          state: { path: location?.pathname, showId: showId },
        });
      }
    }
  };

  const proceedToPayment = async (selectedPlan) => {
    const paymentInfo = {
      subId: selectedPlan,
    };
    try {
      const response = await stripeSession(appInfo, paymentInfo);

      if (response?.status === 200) {
        window.open(response?.data?.url, "_self")
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  }

  const watchVideoHandler = async (videoDetails, showDetails, directSubscribe, directSubscribeData) => {
    setSelectedVideoDetails({ ...videoDetails, showId: showId });
    playVideo(videoDetails, showDetails);
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    if (!trailerPlaying) {
      player.on("play", () => {
        let event = videoStarted == true ? "POP09" : "POP02";
        videoStarted = true;
        videoPlaying = true;
        updateLiveAnalytics(event, player.currentTime());
      });
      player.on("loadedmetadata", () => {
        // player.pause();
        if (watchDuration > 0) {
          player.currentTime(watchDuration.toString());
          // player.play();
          // videoPlaying = true;
        } else {
          // player.play();
          // videoPlaying = true;
        }
      });
      player.on("timeupdate", function (e) {
        let event = "POP03";
        videoPlaying = true;
        var hasPlayedTime = player.currentTime();
        var intPlayedTime = parseInt(hasPlayedTime, 10);
        var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
        if (isSixty && debounce) {
          debounce = false;
          prevTime = intPlayedTime;
          updateLiveAnalytics(event, player.currentTime());
        } else {
          if (debounce == false && prevTime != intPlayedTime) {
            debounce = true;
          }
        }
      });

      player.on("pause", () => {
        let event = "POP04";
        videoPlaying = true;
        updateLiveAnalytics(event, player.currentTime());
      });
      player.on("ended", () => {
        let event = "POP05";
        videoPlaying = false;
        episodeCount = findIndex() + 1;
        updateLiveAnalytics(event, player.currentTime());
        if (episodes[episodeCount]) {
          setNextEpisodeModal(true);
        }
      });
      player.on("dispose", () => {
        videoPlaying = false;
        videoStarted = false;
        player.pause();
      });
    } else {
      player.on("timeupdate", () => {
        videoPlaying = true;
      });
      player.on("dispose", () => {
        videoPlaying = false;
      });
    }
  };

  const updateLiveAnalytics = async (event, time) => {
    const isLive = "0";
    const updateResponse = await onVideoPlayFunction(appInfo, selectedVideoDetails, event, time, isLive, categories);
  };

  const findIndex = () => {
    let elementIndex = null;
    if (episodes) {
      episodes?.filter((filteringItem, index) => {
        if (filteringItem?.video_id === selectedVideoDetails?.video_id) {
          elementIndex = index;
        }
      });
    }
    return elementIndex;
  };

  window.addEventListener("enterpictureinpicture", () => {
    setBrowserPipMode(true);
  });

  window.addEventListener("leavepictureinpicture", () => {
    setBrowserPipMode(false);
  });

  const executeScroll = () => {
    return scrollDownRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div class="detailsScreen" style={{
      backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "var(--secondary-bg-color)"
    }}>
      {ratingReviewModal && (
        <RatingsAndReviewModal
          showName={showDetails?.show_name}
          director={showDetails?.director}
          year={showDetails?.year}
          showId={showDetails?.show_id}
          setRatingReviewModal={setRatingReviewModal}
          setUserRatedValue={setUserRatedValue}
        />
      )}
      {trailerModal && <TrailerModal teaser={showDetails?.teaser} appInfo={appInfo} setTrailerModal={setTrailerModal} showDetails={showDetails} />}

      <div
        class="topBackgroundContainer"
        style={{
          backgroundImage: `url(${imageUrlCheck(showDetails?.logo_thumb) === true
            ? showDetails?.logo_thumb
            : process.env.REACT_APP_IMAGE_URL + showDetails?.logo_thumb
            })`,
        }}
      >
        <div className="contents">

          <div class="top">
            {showDetails?.imdb_rating && (
              <>
                <div class="ratings">
                  <div class="icon">
                    <StarIcon />
                  </div>
                  <h1 class="rating">
                    {showDetails?.imdb_rating} <span class="outOf">/10</span>
                  </h1>
                </div>

                <span class="totalRatings">{showDetails?.number_of_rating} Ratings</span>
              </>
            )}
            {showDetails?.teaser && (
              <div class="trailer">
                <button class="trailerBtn" onClick={() => setTrailerModal(true)}>
                  TRAILER
                  <div class="icon">
                    <PlayIcon />
                  </div>
                </button>
              </div>
            )}
          </div>

          <div className="topLeft">
            <Link to="/home">
              < ArrowIcon />
            </Link>
          </div>
          <div class="left">

            <h1 class="title">{showDetails?.show_name}</h1>
            {showDetails?.director && (
              <h2 class="director">
                Directed by <span class="name">{showDetails?.director}</span>
              </h2>
            )}
            {/* <span class="country">India</span> */}
            {showDetails?.year && <span class="year">{showDetails?.year}</span>}
            <div class="showMoreInfo">
              {showDetails?.duration_text && (
                <div class="duration">
                  <div class="icon">
                    <ClockIcon />
                  </div>
                  <span>{showDetails?.duration_text}</span>
                </div>
              )}
              {showDetails?.audio_language_name && (
                <div class="language">
                  <div class="icon">
                    <Speaker />
                  </div>
                  <span>{showDetails?.audio_language_name}</span>
                </div>
              )}
              {showDetails?.rating && (
                <div className="rating">
                  <span>{showDetails?.rating}</span>
                </div>
              )}
            </div>

            <div className="actions">
              <div className="socialLinks">
                {showDetails?.social_media_links?.map((item, index) => {
                  if (item?.name === "Facebook") {
                    return (
                      <div className="facebookLink" key={index} onClick={() => window.open(item?.link, "_blank")}>
                        <FacebookIcon />
                      </div>
                    );
                  } else if (item?.name === "Instagram") {
                    return (
                      <div className="instagramLink" key={index} onClick={() => window.open(item?.link, "_blank")}>
                        <InstagramIcon />
                      </div>
                    );
                  }
                })}
              </div>
              {!userRatedCount && (
                <div className="addRating" title="Rate and Review" onClick={() => setRatingReviewModal(true)}>
                  <AddRatings />
                </div>
              )}

              <div className="favourite">
                {favouriteStatus === "liked" ? (
                  <Favourited title="Remove From Favourites" onClick={() => removeFromFavourites()} />
                ) : (
                  <AddToFavourite title="Add to Favourites" onClick={() => addToFavourites()} />
                )}
              </div>
              <div class="watchListButton">
                {watchlistStatus === "added" ? (
                  <WatchlistRemove
                    title="Remove From Watchlist"
                    onClick={() => removeFromWatchlist(showDetails?.show_id)}
                  />
                ) : (
                  <WatchlistAdd title="Add to Watchlist" onClick={() => addToWatchlist(showDetails?.show_id)} />
                )}
              </div>
              {userRatedCount && (
                <div className="userRatings">
                  <div className={userRatedCount >= 1 ? "active star" : "no-active star"}>
                    <StarIcon />
                  </div>
                  <div className={userRatedCount >= 2 ? "active star" : "no-active star"}>
                    <StarIcon />
                  </div>
                  <div className={userRatedCount >= 3 ? "active star" : "no-active star"}>
                    <StarIcon />
                  </div>
                  <div className={userRatedCount >= 4 ? "active star" : "no-active star"}>
                    <StarIcon />
                  </div>
                  <div className={userRatedCount >= 5 ? "active star" : "no-active star"}>
                    <StarIcon />
                  </div>
                </div>
              )}
            </div>
            {showDetails?.external_links?.length > 0 && (
              <div className="externalLinks">
                {showDetails?.external_links?.map((item, index) => (
                  <div
                    className="imageContainer"
                    key={index}
                    title={item?.name}
                    onClick={() => window.open(item?.link_url, "_blank")}
                  >
                    <img src={item?.image_url} alt="Icon" />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div class="right">
            {showDetails?.synopsis && (
              <div class="synopsis">
                <h1 class="head">SYNOPSIS</h1>
                <p class="synopis">{showDetails?.synopsis}</p>
              </div>
            )}
            {showDetails?.our_take && (
              <div class="ourTake">
                <h1 class="head">OUR TAKE</h1>
                <p class="ourTake">{showDetails?.our_take}</p>
              </div>
            )}
          </div>
        </div>

        <div className="bottomGradient"></div>
        <div className="pageDown" onClick={executeScroll}>
          <PageDownArrow />
        </div>
        <div className="shareContainer">
          <span className={isShareActive ? "active" : ""} ref={shareRef} onClick={() => setIsShareActive(true)}>
            Share
          </span>
          {isShareActive && (
            <div className="socialIcons">
              <FacebookShareButton url={pageURL} quote={"Share"} className="facebook">
                <div className="icon">
                  <FacebookIcon />
                </div>
              </FacebookShareButton>

              <TwitterShareButton url={pageURL} className="twitter">
                <div className="icon">
                  <TwitterIcon />
                </div>
              </TwitterShareButton>
              <CopyToClipboard className="clipBoard" text={pageURL} title="Copy link">
                <div className="icon" onClick={() => alert("Link copied")}>
                  <ClipBoard />
                </div>
              </CopyToClipboard>
            </div>
          )}
          <div className="icon" ref={shareRef}>
            {isShareActive ? (
              <Closeicon className="closeIcon" onClick={() => setIsShareActive(false)} />
            ) : (
              <ShareIcon onClick={() => setIsShareActive(true)} />
            )}
          </div>
        </div>
        <div className="playIconContainer" onClick={() => watchVideoHandler(selectedVideoDetails)}>
          <PlayIcon />
        </div>
        {!subscribedUser || selectedVideoDetails?.free_video === true ? (

          showDetails?.subscriptions?.length > 0 || selectedVideoDetails?.free_video === true ? (
            <div className="subscriptions">
              {showDetails?.subscriptions?.map((item, index) => (
                <div className="items" key={index} onClick={() => handleSubscribe(item)}>
                  {item?.subscription_text}
                </div>
              ))}
              {showDetails?.single_video === 1 && selectedVideoDetails?.free_video === true && (
                <div className="items free" onClick={() => watchVideoHandler(selectedVideoDetails)}>
                  Free
                </div>
              )}
            </div>

          ) : null

        ) : null}
      </div>
      <div className="responsiveContent">
        {/* <div className="categoryNameSM">Categrory Name</div> */}
        {showDetails?.synopsis && (
          <div className="synopsisSM">
            <h1 className="heading">SYNOPSIS</h1>
            <p className="synopsis">{showDetails?.synopsis}</p>
          </div>
        )}
        {showDetails?.our_take && (
          <div className="ourTakeSM">
            <h1 className="heading">OUR TAKE</h1>
            <p className="ourTake">{showDetails?.our_take}</p>
          </div>
        )}
        {showDetails?.teaser && (
          <div class="trailer">
            <button class="trailerBtn" onClick={() => setTrailerModal(true)}>
              TRAILER
              <div class="icon">
                <PlayIcon />
              </div>
            </button>
          </div>
        )}
      </div>

      {showDetails?.single_video === 0 && showDetails?.videos?.length > 1 && showDetails?.videos[0]?.videos && (
        <ShowsRow
          season={true}
          data={showDetails?.videos}
          metaData={false}
          watchVideoHandler={watchVideoHandler}
          thumbnailOrientation={thumbnailOrientation}
          type="Episode"
        />
      )}
      {showDetails?.single_video !== 1 && showDetails?.videos?.length === 1 && showDetails?.videos[0]?.season && (
        <ShowsRow
          season={false}
          data={showDetails?.videos[0]?.videos}
          title={"Episodes"}
          metaData={false}
          watchVideoHandler={watchVideoHandler}
          type="Episode"
          thumbnailOrientation={thumbnailOrientation}
        />
      )}
      {showDetails?.single_video === 0 && !showDetails?.videos[0]?.videos && (
        <ShowsRow
          season={false}
          data={showDetails?.videos}
          title={"Episodes"}
          metaData={false}
          watchVideoHandler={watchVideoHandler}
          type="Episode"
          thumbnailOrientation={thumbnailOrientation}
        />
      )}
      {showDetails?.categories?.length > 0 && (
        <CategoriesSection showDetails={showDetails} scrollDownRef={scrollDownRef} />
      )}
      {showDetails?.key_art_work?.length > 0 && <AboutSection showDetails={showDetails} />}

      {showDetails?.awards?.length > 0 && <AwardsAndFestivals showDetails={showDetails} />}

      {showDetails?.cast?.length > 0 && <CastAndCrew type="Cast" data={showDetails?.cast} />}

      {showDetails?.crew?.length > 0 && <CastAndCrew type="Crew" data={showDetails?.crew} />}

      <div className="relatedFilms" style={{
        backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "var(--secondary-bg-color)"
      }}>
        <h1 className="heading">RELATED FILMS</h1>
        <div className="films">
          {similarVideos?.map((item, index) => (
            <div
              className={
                thumbnailOrientation === `PORTRAIT`
                  ? `items portrait ${rowItemsClass}`
                  : `items landscape ${rowItemsClass}`
              }
              key={index}
            >
              <ShowCard data={item} imageUrl={true} metaData={true} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailsScreen;
