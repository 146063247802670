import { createSlice } from "@reduxjs/toolkit";

export const howItWorksSlice=createSlice({
    name:"howItWorksModal",
    initialState:{
        value:{
            isOpen:false,
        }
    },
    reducers:{
        getHowItWorksModal:(state,action)=>{
            state.value=action?.payload?.howItWorksModal
        }
    }
})

export const {getHowItWorksModal}=howItWorksSlice.actions
export default howItWorksSlice.reducer