import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';

import CategoriesShowCard from '../../Components/CategoriesShowCard/CategoriesShowCard';
import Loading from '../../Components/Loading/Loading';
import PlayerShowCard from '../../Components/PlayerShowCard/PlayerShowCard';
import Categories from './Components/Categories';
import Featured from './Components/Featured';
import Live from './Components/Live';
import * as service from "./service";
import useScrollPosition from '../../Components/ScrollPosition/useScrollPosition';
const Homepage = () => {
  const projectInfo=useSelector((state)=>state?.projectInfo?.value);
  const accessToken=useSelector((state)=>state?.accessToken?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;

  const [loading ,setLoading] = useState(true)
  const [homeData,setHomeData] = useState([])
  const [homeDataSliceCount, setHomeDataSliceCount] = useState(8);


  
  const homePageContainerRef = useRef()
  const scrollPosition = useScrollPosition();

  const appInfo={
    projectDetails:projectInfo,
    accessToken:accessToken
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    if(!loading && homeDataSliceCount<homeData?.length){
      lazyLoad()
    }
  },[loading,scrollPosition,homeData])
  useEffect(()=>{
    window.scroll(0,0)
   setLoading(true)
     fetchShowDetails();

  },[])

  
  
  const fetchShowDetails = async()=>{
    try{
      const response = await  service.getShowsByCategory(appInfo);
      if(response?.status === 200){
        setHomeData(response?.data?.data)
        setLoading(false)
      }
    }
    catch{
      setLoading(true)
    }
  }

  const lazyLoad = () => {
    const containerHeight = homePageContainerRef?.current?.clientHeight;
    if(scrollPosition + 2500 >= containerHeight){
      setHomeDataSliceCount(homeDataSliceCount+5)
    }
  }

if(loading){
  return(
    <Loading/>
  )
}

  return (
    <div className='homePage' ref={homePageContainerRef}>
        {
          homeData?.slice(0,homeDataSliceCount)?.map((item,index) => {
            
            if(item?.type==="FEATURED") {
              return <Featured key={index} data={item} />
            }
            else if(item?.banner) {
              return <CategoriesShowCard key={index} data={item} thumbnailOrientation={thumbnailOrientation} />
            }
            else if(item?.type === "LIVE"){
              return <Live data={item}/>
            }
             else if(item?.type!=="LIVE"){
              return <Categories key={index} data={item} thumbnailOrientation={thumbnailOrientation}  type={item?.type} />
            }else{
              return null
            }
})
        }
    </div>
  )
}

export default Homepage
