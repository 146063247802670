import React from "react";
import { imageUrlCheck } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import { getPodcastModal } from "../../Redux/PodcastModal/podcastModalSlice";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
// import 'react-h5-audio-player/lib/styles.less' Use LESS
// import 'react-h5-audio-player/src/styles.scss' 
const PodcastModal = ({ data }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const thumbnailOrientation =
    projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;

  const dispatch = useDispatch();
  const modalClose = () => {
    let podcastData = {
      isOpen: false,
    };
    dispatch(
      getPodcastModal({
        podcastModal: podcastData,
      })
    );
  };

  return (
    <div className="podcastModal">
      <div className="overlayModal"></div>
      <div className="closeIconContainer" onClick={modalClose}>
        <CloseIcon />
      </div>
      <div className="contents">
        <div className="podcastContainer">
          <div className="left">
            {/* <div className="imageContainer">
              {imageUrlCheck(
                thumbnailOrientation === "PORTRAIT"
                  ? data?.logo || data?.thumbnail
                  : data?.logo_thumb || data?.thumbnail_350_200
              ) === false ? (
                <img
                  src={
                    thumbnailOrientation === "PORTRAIT"
                      ? `${process.env.REACT_APP_IMAGE_URL}${
                          data?.logo || data?.thumbnail
                        }`
                      : `${process.env.REACT_APP_IMAGE_URL}${
                          data?.logo_thumb || data?.thumbnail_350_200
                        }`
                  }
                  alt="ShowImage"
                />
              ) : (
                <img
                  src={
                    thumbnailOrientation === "PORTRAIT"
                      ? `${data?.logo || data?.thumbnail}`
                      : `${data?.logo_thumb || data?.thumbnail_350_200}`
                  }
                  alt="ShowImage"
                />
              )}
            </div> */}
          </div>
          <div className="right">
            {/* <h1 className="title">{data?.show_name}</h1> */}
            <div className="audioContainer">
              <AudioPlayer
                autoPlay
                src={data?.url}
                onPlay={(e) => console.log("onPlay")}
                // other props here
              />
              {/* <audio src={data?.url} controls></audio> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastModal;
