import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/Icons/search.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/closeFilled.svg";
import { ReactComponent as MenuIcon } from "../../assets/Icons/MenuIcon.svg";
import { ReactComponent as CircleArrow } from "../../assets/Icons/filledCircleArrow.svg";
import { ReactComponent as SignOutIcon } from "../../assets/Icons/signOut.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import {
  appInfo,
  authenticate,
  getUserSubscription,
} from "../../network/service";
import * as service from "./service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import SmallLoading from "../Loading/SmallLoading";
import { imageUrlCheck, getDeviceType } from "../../utils/utils";
import { getHowItWorksModal } from "../../Redux/HowItWorksModal/howItWorksSlice";
import { getLanguageModal } from "../../Redux/LanguageModal/languageModalSlice";


const Header = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  const selectedLanguage = useSelector(
    (state) => state?.languageModal?.value?.selectedLanguage
  );
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [menuItems, setMenuItems] = useState();
  const [browseHover, setBrowseHover] = useState(false);
  const [browseMoreMediumDevice, setBrowseMoreMediumDevice] = useState(false);
  const [position, setPosition] = useState(window.pageYOffset);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [userInfo, setUserInfo] = useState(false);
  const [previousLocation, setPreviousLocation] = useState("");
  const [menuHandler, setMenuHandler] = useState(false);
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [IsSearchLoading, setIsSearchLoading] = useState(false);
  const [isSubscribedUser, setIsSubscribedUser] = useState(false)
  const [deviceType, setDeviceType] = useState();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const menuRef = useRef(null);
  const searchRefContainer = useRef(null);
  const searchRefSmall = useRef(null);
  const searchRefSmallContainer = useRef(null);
  const searchSuggestionRef = useRef(null);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };


  useEffect(() => {
    setDeviceType(getDeviceType())
  }, [])

  useEffect(() => {

    const handleScroll = () => {
      let moving = window.pageYOffset;
      // setHeaderVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(() => {
    if (searchActive) {
      // searchRef?.current.focus();
      searchRefSmall?.current?.focus();
      searchRefSmallContainer?.current?.focus();
      setPreviousLocation(location);
    }
  }, [searchActive]);

  useEffect(() => {
    if (accessToken) {
      fetchUserSubscriptionDetails();
    }
  }, [user, accessToken]);

  useEffect(() => {
    fetchMenuItems();
  }, []);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !searchRefContainer?.current?.contains(e.target) &&
        !searchRefSmallContainer?.current?.contains(e.target)
      ) {
        setInputValue("");
        setSearchActive(false); //outside click
      }

      if (!searchSuggestionRef?.current?.contains(e.target)) {
        setInputValue("");
        // setSearchActive(false)
        setSearchResults(null);
      }

      if (!menuRef?.current?.contains(e.target)) {
        setMenuHandler(false); //outside click
      }
      // console.log(e, "e");
    };

    window.addEventListener("click", handleOutClick);
    return () => {
      window.addEventListener("click", handleOutClick);
    };
  }, [
    searchRefContainer,
    searchRefSmallContainer,
    menuRef,
    searchSuggestionRef,
  ]);

  useEffect(() => {
    if (inputValue) {
      setIsSearchLoading(true);
      fetchSearchData();
    } else {
      setIsSearchLoading(false);
      setSearchResults(null);
    }
  }, [inputValue]);

  // useEffect(() => {
  //   searchResultsShow();
  // }, [searchResults]);
  const fetchMenuItems = async () => {
    try {
      const menuResponse = await service.getMenuItems(appInfo);
      if (menuResponse?.status === 200) {
        setMenuItems(menuResponse?.data?.data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
    }
  };

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {

      if (userSubResponse?.data?.length > 0) {
        setIsSubscribedUser(true)
      } else {
        setIsSubscribedUser(false)
      }

      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };

  /// Search functionality  start
  const searchSubmit = (value) => {
    if (value) {
      setInputValue("");
      setSearchActive(false);
      if (searchResults?.length > 0) {
        navigate(`/search?query=${value}`, { state: { searchResults } });
      } else {
        navigate(`/search?query=${value}`);
      }
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
  };
  const searchSubmitBrowseMore = (value) => {
    setSearchActive(true);
    if (value) {
      setInputValue("");
      if (searchResults?.length > 0) {
        navigate(`/search?query=${value}`, { state: { searchResults } });
      } else {
        navigate(`/search?query=${value}`);
      }
      setBrowseMoreMediumDevice(null);
    } else {
      navigate(previousLocation?.pathname, { state: previousLocation?.state });
      setInputValue("");
    }
    // if (!searchActive) {
    //   setSearchActive(true);
    // } else {
    //   setSearchActive(false);
    //   setBrowseMoreMediumDevice(null);
    // }
  };

  /// end

  const logoutHandler = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedSubId");
    localStorage.removeItem("deviceType");
    tokenAuthenticate();
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
      navigate("/home");
      dispatch(
        getUser({
          user: null,
        })
      );
    }
  };

  const fetchSearchData = async () => {
    try {
      const response = await service?.getSearchData(appInfo, inputValue);
      if (response?.status === 200) {
        setSearchResults(response?.data?.data);
        setIsSearchLoading(false);
      } else {
      }
    } catch (err) {
      setIsSearchLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const showClickHandler = (data, medium) => {
    if (medium) {
      setBrowseMoreMediumDevice(false);
    }
    if (data?.type === "LIVE_EVENT") {
      navigate(`/event/${data?.show_id}`, { state: { data } })

    } else if (data?.type === "PODCAST") {
      let podcastData = {
        isOpen: true,
        data
      }

    } else if (data?.type === "NEWS") {
      navigate(`/news-info/${data?.show_id}`)
    }
    else if (data?.type === "CONTINUE_WATCHING") {
      navigate(`/movies/${data?.vanity_url ? data?.vanity_url : data?.event_id}`, {
        state: { showId: data?.show_id, type: data?.type, vanityUrl: data?.vanity_url },
      })
    }
    else if (data?.type === "UPCOMING_EVENT") {
      navigate(`/event/${data?.show_id}`, { state: { data } })

    }
    else if (data?.type === "VIDEO") {
      navigate(`/videos/${data?.vanity_url ? data?.vanity_url : data?.show_id}`, {
        state: { videoDetails: data },
      })
    }
    else if (data?.type === "SHOW") {
      navigate(`/movies/${data?.vanity_url ? data?.vanity_url : data?.event_id}`, {
        state: { showId: data?.show_id, type: data?.type, vanityUrl: data?.vanity_url },
      })
    }
    else if (data?.type === "FASTCHANNEL") {
      navigate(`/live-channels`, {
      })
    }
    else if (data?.type === "EVENT") {
      navigate(`/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`)
    }

    else {
      navigate(`/movies/${data?.vanity_url ? data?.vanity_url : data?.event_id}`, {
        state: { showId: data?.show_id, type: data?.type, vanityUrl: data?.vanity_url },
      })

      setSearchResults(null);
    }
  };
  const searchClose = () => {
    setSearchActive(false);
    setInputValue("");
    setSearchResults(null);
  };

  const howItWorksModalHandler = () => {
    dispatch(
      getHowItWorksModal({
        howItWorksModal: { isOpen: true },
      })
    );
  };

  const languageHandler = () => {
    let data = {
      isOpen: true,
      selectedLanguage: selectedLanguage ? selectedLanguage : null,
    };
    dispatch(
      getLanguageModal({
        languageModal: data,
      })
    );
  };

  return (
    <>
      <ToastContainer />
      <div
        className={browseMoreMediumDevice ? "active overlayOne" : "overlayOne"}
        onClick={() => setBrowseMoreMediumDevice(false)}
      ></div>
      <div
        className={
          position === 0 ? "header-container" : "header-container background"
        }
        style={{
          borderBottomColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "--border-color"
        }}
      >
        <div className="wrapper">
          <div className="leftContainer">
            <div className="logoContainer">
              <Link to="/home">
                <h1>
                  <img
                    src={projectInfo?.projectConfig?.config?.LOGO}
                    alt="Logo"
                  />
                </h1>
              </Link>
            </div>
            <div
              className={
                searchActive ? "inputContainer showInput" : "inputContainer"
              }
              ref={searchRefContainer}
              onClick={() => {
                setSearchActive(true);
                searchRef?.current.focus();
              }}
              style={{
                borderLeft: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR
                  ? "1px solid var(--grey-color)"
                  : "1px solid var(--white-color)",
                backgroundColor: searchActive
                  ? projectInfo?.projectConfig?.config?.BACKGROUND_COLOR
                    ? "var(--sub-bg-color)"
                    : "#f6f6f6"
                  : "transparent",
              }}

            >
              {searchActive ? (
                <SearchIcon
                  onClick={
                    () => searchSubmit(inputValue)
                    // setSearchActive(!searchActive)
                  }
                />
              ) : (
                <SearchIcon
                  onClick={() => {
                    searchRef?.current.focus();
                    setSearchActive(true);
                  }}
                />
              )}

              <input
                ref={searchRef}
                onClick={() => setSearchActive(true)}
                type="text"
                value={inputValue || ""}
                // placeholder={searchActive && "Find Movies, TV shows and more"}
                placeholder="Search"
                onChange={(e) => {
                  // searchHandleChange(e?.target?.value);
                  setInputValue(e?.target?.value);
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" ? searchSubmit(inputValue) : ""
                }
              />
              {IsSearchLoading && (
                <div className="searchLoading">
                  <SmallLoading />
                </div>
              )}

              {!IsSearchLoading && searchResults?.length > 0 && inputValue && (
                <div className="closeIcon">
                  <CloseIcon onClick={() => searchClose()} />
                </div>
              )}
              {searchResults?.length > 0 && inputValue && (
                <div
                  className="searchSuggestionsContainer"
                  ref={searchSuggestionRef}
                  style={{
                    backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "--bg-color"
                  }}
                >
                  <h6 className="heading">
                    Total <span className="count">{searchResults?.length}</span>
                  </h6>
                  <ul className="lists">

                    {searchResults?.slice(0, 8)?.map((item, index) => (
                      <div key={index} className="categorySection">
                        <h6 className="heading">
                          {item?.category_name}
                        </h6>
                        {item?.shows?.map((show, showIndex) => (
                          <li
                            className="list"
                            key={`${index}-${showIndex}`}
                            onClick={() => showClickHandler(show)}
                          >
                            <div className="listContainer">

                              <div className="left">
                                <div className="imageContainer">
                                  {imageUrlCheck(
                                    thumbnailOrientation === "PORTRAIT"
                                      ? show?.logo
                                      : show?.logo_thumb
                                  ) ? (
                                    <img
                                      src={
                                        thumbnailOrientation === "PORTRAIT"
                                          ? `${show?.logo}`
                                          : `${show?.logo_thumb}`
                                      }
                                      alt="Thumb"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        thumbnailOrientation === "PORTRAIT"
                                          ? `${process.env.REACT_APP_IMAGE_URL}${show?.logo}`
                                          : `${process.env.REACT_APP_IMAGE_URL}${show?.logo_thumb}`
                                      }
                                      alt="Thumb"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="right">
                                <h1 className="title">
                                  {show?.show_name?.slice(0, 50)}
                                  {show?.show_name?.length > 50 && "..."}
                                </h1>
                                <span className="director">{show?.director}</span>
                              </div>

                            </div>
                          </li>
                        ))}
                      </div>
                    ))}
                  </ul>
                  <div
                    className="seeAll"
                    onClick={() => searchSubmit(inputValue)}
                  >
                    <button>SEE ALL</button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="rightContainer">
            {
              projectInfo?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED === "true" &&
              <div className="language" onClick={languageHandler}>
                <i class="fa-solid fa-globe"></i>
                <span>
                  {selectedLanguage !== null ? selectedLanguage : "Language"}
                </span>
              </div>
            }

            <div className="liveButtonContainer">
              {
                projectInfo?.projectConfig?.config?.LIVE_REQUIRED === "true" &&
                <ul className="liveButton">
                  <li className={location?.pathname === "/live" ? "active" : ""} onClick={() => {
                    (
                      projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true" ? navigate('live-channels') : navigate('live')
                    )
                  }}>
                    Live
                  </li>
                </ul>
              }

            </div>
            {projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' && !isSubscribedUser && deviceType != 'mobile' &&
              < div style={{ marginTop:'4px'}} >
                <h4 onClick={() => navigate("/checkout")} style={{ textTransform: 'uppercase', cursor: 'pointer' }}>{projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_BUTTON_TEXT || 'Subscribe'}</h4>
              </div>}


            <ul className={`browse ${searchActive ? "hide" : ""} ${projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? "with-bg" : "without-bg"}`} >
              {
                Array.isArray(menuItems) && menuItems.length > 0 &&
                <li
                  className={browseHover ? "active" : "no-active"}
                  onClick={() => {
                    setBrowseHover(!browseHover);
                    setBrowseMoreMediumDevice(true);
                    setMenuHandler(false);
                  }}
                >
                  <span>BROWSE</span>
                </li>
              }
            </ul>
            {user ? (
              <h4>Hi,{userDetails?.first_name}</h4>
            ) : (
              <div className="buttonContainer">
                <button className="signIn" onClick={() => navigate("/login")}>
                  LOG IN
                </button>
              </div>
            )}
            <div className="menuIconContainer">
              <MenuIcon
                ref={menuRef}
                onClick={() => setMenuHandler(!menuHandler)}
              />
            </div>
            {menuHandler && (
              <div className="menuListContainer">
                <ul className="list">
                  {user && Array.isArray(menuItems) && menuItems.length > 0 && (
                    <Link to="/my-list">
                      <li className={projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? 'bg-set' : 'bg-not-set'}>
                        <span>My List</span>
                      </li>
                    </Link>
                  )}
                  <li onClick={() => navigate("/privacy-policy")} className={projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? 'bg-set' : 'bg-not-set'}>
                    <span>Privacy Policy</span>
                  </li>
                  <li onClick={() => navigate("/terms-and-conditions")} className={projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? 'bg-set' : 'bg-not-set'}>
                    <span>Terms of Use</span>
                  </li>
                  <li onClick={() => navigate("/about-us")} className={projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? 'bg-set' : 'bg-not-set'}>
                    <span>About Us</span>
                  </li>
                  {projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true' ?
                    <li onClick={() => navigate("/checkout")} className={projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? 'bg-set' : 'bg-not-set'}>
                      <span>{projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_BUTTON_TEXT}</span>
                    </li> :
                    <li onClick={() => navigate("/subscription")} className={projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? 'bg-set' : 'bg-not-set'}>
                      <span>Subscription plans</span>
                    </li>}
                  {projectInfo?.projectConfig?.pubid === 50030 && (
                    <li onClick={() => howItWorksModalHandler()} className={projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? 'bg-set' : 'bg-not-set'}>
                      <span>How it works</span>
                    </li>
                  )}
                  {user && (
                    <>
                      <Link to="/settings">
                        <li className={projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? 'bg-set' : 'bg-not-set'}>
                          <span>Settings</span>
                        </li>
                      </Link>

                      <Link to="/tv">
                        <li className={projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? 'bg-set' : 'bg-not-set'}>
                          <span>Link TV App</span>
                        </li>
                      </Link>
                    </>
                  )}
                  {user && (
                    <li onClick={() => logoutHandler()} className={projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? 'bg-set' : 'bg-not-set'}>
                      <span>Log Out</span>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div >

      <div className={browseHover ? "browseMore active" : "browseMore"}>
        <div className="overlay" onClick={() => setBrowseHover(null)}></div>

        <div className="browseMoreContainer"
          style={{
            backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "--bg-color",
          }}
        >
          {menuItems?.map((item, index) => (
            <div className="left" key={index}>
              <h1 className="categoryName">{item?.key}</h1>
              <ul>
                {item?.items?.map((itemShow, keyIndex) => (
                  <li key={keyIndex}>
                    <Link
                      to={`/category/${itemShow?.key}`}
                      state={{ careers: itemShow?.key }}
                      onClick={() => setBrowseHover(null)}
                    >
                      <span style={{
                        color: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR ? "var(--text-color)" : "var(--default-bg-color)"
                      }}>
                        {itemShow?.value}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="browseMoreResponsive">
        {browseMoreMediumDevice && (
          <div
            className={
              browseMoreMediumDevice ? "searchArea active" : "searchArea"
            }
          >
            <div className="inputContainer" ref={searchRefSmallContainer}>
              <SearchIcon
                onClick={() => {
                  // searchResultsShow();
                  searchSubmitBrowseMore(inputValue);
                }}
              />

              <input
                ref={searchRefSmall}
                onClick={() => {
                  setSearchActive(true);
                }}
                type="text"
                value={inputValue}
                placeholder={!searchActive && "Search"}
                onChange={(e) => {
                  // searchHandleChange(e?.target?.value);
                  setInputValue(e?.target?.value);
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" ? searchSubmitBrowseMore(inputValue) : ""
                }
              />
            </div>
            {IsSearchLoading && (
              <div className="searchLoading">
                <SmallLoading />
              </div>
            )}

            {!IsSearchLoading && searchResults?.length > 0 && inputValue && (
              <div className="closeIcon">
                <CloseIcon onClick={() => searchClose()} />
              </div>
            )}
            {searchResults?.length > 0 && inputValue && (
              <div
                className="searchSuggestionsContainerMedium"
                ref={searchSuggestionRef}
              >
                <h6 className="heading">
                  Total <span className="count">{searchResults?.length}</span>
                </h6>
                <ul className="lists">
                  {searchResults?.slice(0, 8)?.map((item, index) => (
                    <li
                      className="list"
                      key={index}
                      onClick={() => showClickHandler(item, true)}
                    >
                      <div className="left">
                        <div className="imageContainer">
                          {imageUrlCheck(
                            thumbnailOrientation === "PORTRAIT"
                              ? item?.logo || item?.thumbnail
                              : item?.logo_thumb || item?.thumbnail_350_200
                          ) === false ? (
                            <img
                              src={
                                thumbnailOrientation === "PORTRAIT"
                                  ? `${process.env.REACT_APP_IMAGE_URL}${item?.logo || item?.thumbnail
                                  }`
                                  : `${process.env.REACT_APP_IMAGE_URL}${item?.logo_thumb ||
                                  item?.thumbnail_350_200
                                  }`
                              }
                              alt="Thumb"
                            />
                          ) : (
                            <img
                              src={
                                thumbnailOrientation === "PORTRAIT"
                                  ? `${item?.logo || item?.thumbnail}`
                                  : `${item?.logo_thumb ||
                                  item?.thumbnail_350_200
                                  }`
                              }
                              alt="Thumb"
                            />
                          )}
                        </div>
                      </div>

                      <div className="right">
                        <h1 className="title">{item?.show_name}</h1>
                        <span className="director">{item?.director}</span>
                      </div>
                    </li>
                  ))}
                </ul>
                <div
                  className="seeAll"
                  onClick={() => searchSubmitBrowseMore(inputValue)}
                >
                  <button>SEE ALL</button>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={browseMoreMediumDevice ? "container active" : "container"}
        >
          {
            projectInfo?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED === "true" &&

            <div className="language" onClick={languageHandler}>
              <i class="fa-solid fa-globe"></i>
              <span>
                {selectedLanguage !== null ? selectedLanguage : "Language"}
              </span>
            </div>
          }
          {
            Array.isArray(menuItems) && menuItems.length > 0 &&
            <div className="head large">
              <div className="left">
                <h1>Browse</h1>
              </div>
              <div className="right">
                <CloseIcon onClick={() => setBrowseMoreMediumDevice(false)} />
              </div>
            </div>
          }
          {menuItems?.map((item, index) => (
            <div className="top" key={index}>
              <h1 className="categoryName">{item?.key}</h1>
              <ul>
                {item?.items?.map((items, i) => (
                  <li key={i}>
                    <Link
                      to={`/category/${items?.key}`}
                      state={{ careers: items?.key }}
                      onClick={() => setBrowseMoreMediumDevice(false)}
                    >
                      <span> {items?.value}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Header;
