import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ShowCard from "../ShowCard/ShowCard";
import Categories from "../../Screens/homepage/Components/Categories";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CategoriesShowCard = ({ data,thumbnailOrientation }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const navigate = useNavigate();

  return (
    <div className="categoriesShowCard">
      <div className="backgroundImageContainer">
      
        <img className="bgImage" src={data?.banner} alt="Banner" />
        <div className="metaInfo">

        <h1 className="categoryName" onClick={() =>
            navigate(`/category/${data?.key}`, {
              state: {careers:data?.key,type:data?.type,banner:data?.banner},
            })
          }>{data?.category_name}</h1>
        <p className="description">{data?.synopsis}</p>
        </div>

        <div className="AllShows">
          <Swiper
            modules={[Navigation]}
            spaceBetween={5}
            slidesPerView={4}
            navigation={true}
            watchSlidesProgress
            loop={false}
            breakpoints={{
              320: {
                slidesPerView: thumbnailOrientation=="PORTRAIT"?2:1,
                spaceBetween: 2,
              },
              480:{
                slidesPerView: thumbnailOrientation=="PORTRAIT"?3:2,
                spaceBetween: 5,
              },
              640: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: thumbnailOrientation=="PORTRAIT"?6:4,
                spaceBetween: 1.5,
              },
              980:{
                slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:4,
                spaceBetween: 1.5,
              },
              // 1200:{
              //   slidesPerView: thumbnailOrientation=="PORTRAIT"?6:4,
              //   spaceBetween: 1.5,
              // },
              // 1700:{
              //   slidesPerView: thumbnailOrientation=="PORTRAIT"?7:4,
              //   spaceBetween: 1.5,
              // }
            }}
          >
            {data?.shows?.map((item, index) => (
              <SwiperSlide>
                <div className="shows">
                  <ShowCard
                    data={item}
                    season={false}
                    type={data?.type}
                    metaData={true}
                  />
                </div>
              </SwiperSlide>
            ))}  
          </Swiper>

          
          
        </div>
      </div>
      <div className="bottomGradient"></div>
    </div>
  );
};

export default CategoriesShowCard;
